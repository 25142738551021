import { api } from './client';

export const getGroups = async () => {
    const result = await api.get(`/groups`);
    return result.data;
}

export const updateGroup = async (group) => {
    await api.put(`/groups/${group.id}`, group);
};
