const baseStyles = (theme) => ({
    root: {
        minHeight: '90vh',
        // maxWidth: '1920px',
        width: "90%",
        margin: 'auto',

        [theme.breakpoints.up('xl')]: {
            width: '1920px',
            // height: "auto",
        },
    },
    section: {
        marginTop: '60px',
        marginBottom: '40px' // todo fix this.. it will cause problems. add a gap instead.
    },
    buttonIcon: {
        marginRight: "5px"
    },
    sideNavRoot: {
        position: 'relative',
        minHeight: '90vh',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'start',

        '& $mainContent': {
            marginLeft: 'auto',
            marginRight: 'auto'
        },

        [theme.breakpoints.up('xl')]: {
            width: '1920px',
            // height: "auto",
        },
    },
    sideNav: {
        width: '200px',
        alignSelf: 'stretch'
    },
    mainContent: {
        width: "80%",
    }
});

export default baseStyles;