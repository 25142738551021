import React, { useState } from 'react';
import Button from '@mui/material/Button';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import DeleteForever from '@mui/icons-material/DeleteForever';
import ScannerIcon from '@mui/icons-material/Scanner';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from "react-router-dom";
import { useHistoryState } from '../Common/useHistoryState';
import baseStyles from '../Common/styles';
import { EmptyStateWithTable as EmptyState } from '../Common/EmptyState';
import CircularProgress from '@mui/material/CircularProgress';
import InventorySpreadsheetUploadDialog from './InventorySpreadsheetUploadDialog';
import ScanUploadDialog from './ScanUploadDialog';
import { getSearchFieldsWithOptions, filterMaterials, Search } from '../Common/search';
import { useQuery, useQueryClient } from 'react-query';
import * as api from '../Api/fabricApi.js';
import FadeIn from 'react-fade-in';
import MaterialTable from '../Common/MaterialsTable/MaterialsTable';
import CheckIcon from '@mui/icons-material/Check';
import PublishAllConfirmDialog from './PublishAllConfirmDialog';

const useStyles = makeStyles((theme) => ({
    ...baseStyles(theme),
    buttonBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& button': {
            marginRight: '5px'
        },
    },
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(3),
    },
    emptyState: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute'
    },
    stackButton: {
        gap: '5px'
    }
}));

const searchFields = [
    {
        label: "Supplier Article No.",
        key: "supplierArticleNumber",
        type: "string"
    },
    {
        label: "Brand",
        key: "brand",
        type: "string"
    },
    {
        label: "Status",
        key: "published",
        type: "boolean"
    },
];
const hasScanFiles = (material) => {
    if (!material.fileTypes) {
        return false;
    }

    let hasU3m = false;
    let hasXtex = false;
    for (let i = 0; i < material.fileTypes.length; i++) {
        if (material.fileTypes[i] === 'u3m') {
            hasU3m = true;
        }
        else if (material.fileTypes[i] === 'xtex') {
            hasXtex = true;
        }
    }
    return hasU3m && hasXtex;
};

export default function InventoryManagement(props) {
    const classes = useStyles();
    const history = useHistory();

    const [inventorySpreadsheetUploadDialogOpen, setInventorySpreadsheetUploadDialogOpen] = useState(false);
    const [scanUploadDialogOpen, setScanUploadDialogOpen] = useState(false);
    const [publishAllDialogOpen, setPublishAllDialogOpen] = useState(false);
    const [filters, setFilters] = useHistoryState('adminMaterialFilters', []);
    const [updatingMaterials, setUpdatingMaterials] = useState({});

    const queryClient = useQueryClient();
    const queryKey = ['materials', updatingMaterials];
    const { isLoading, error, data, refetch } = useQuery(queryKey, () => {
        return api.getAllFabrics().then((result) => {
            setUpdatingMaterials({});
            return result;
        });
    });

    const publishMaterials = async (materials) => {
        await api.updateFabrics(materials.map(m => ({
            ...m,
            published: true
        })));

        const unpublishedMaterialKeys = materials.reduce((keys, m) => {
            keys[m.fileName] = true;
            return keys;
        }, {});

        setUpdatingMaterials({
            ...updatingMaterials,
            ...unpublishedMaterialKeys
        });
    };

    const unpublishMaterials = async (materials) => {
        await api.updateFabrics(materials.map(m => ({
            ...m,
            published: false
        })));

        const publishedMaterialKeys = materials.reduce((keys, m) => {
            keys[m.fileName] = true;
            return keys;
        }, {});

        setUpdatingMaterials({
            ...updatingMaterials,
            ...publishedMaterialKeys
        });
    };

    const deleteMaterial = async (material) => {
        await api.deleteFabric(material.id);
        refetch();
    };

    const attributeColumns = [
        {
            header: "Brand",
            attribute: "brand"
        },
        {
            header: "Supplier Article No.",
            attribute: "supplierArticleNumber"
        }
    ];
    const extraColumns = [
        {
            header: "xtex",
            render: (row) => {
                if (row.fileTypes?.includes("xtex")) {
                    return <CheckIcon />;
                } else {
                    return null;
                }
            }
        },
        {
            header: "u3m",
            render: (row) => {
                if (row.fileTypes?.includes("u3m")) {
                    return <CheckIcon />;
                } else {
                    return null;
                }
            }
        },
        {
            header: "u3ma",
            render: (row) => {
                if (row.fileTypes?.includes("u3ma")) {
                    return <CheckIcon />;
                } else {
                    return null;
                }
            }
        },
        {
            header: "Status",
            render: (row) => {
                if (row.published) {
                    return (
                        <Typography variant="body1">Published</Typography>
                    );
                } else {
                    return (
                        <Typography variant="body1">Not Published</Typography>
                    );
                }
            }
        },
        {
            header: "",
            render: (row) => {
                let deleteButton = <Button
                    color="error"
                    variant="outlined"
                    onClick={() => deleteMaterial(row)}
                ><DeleteForever /></Button>;
                if (updatingMaterials[row.brandItemCode]) {
                    return <CircularProgress></CircularProgress>;
                }
                if (row.published) {
                    return (
                        <Stack direction={row} spacing={2} className={classes.stackButton}>
                            <Button
                                color="error"
                                variant="outlined"
                                onClick={() => unpublishMaterials([row])}
                            >Unpublish</Button>
                            {deleteButton}
                        </Stack>
                    );
                } else {
                    return (
                        <Stack direction={row} spacing={2} className={classes.stackButton}>
                            <Button
                                color="primary"
                                variant="outlined"
                                disabled={!hasScanFiles(row)}
                                onClick={() => publishMaterials([row])}
                            >Publish</Button>
                            {deleteButton}
                        </Stack>
                    );
                }
            }
        }
    ];

    const searchHandler = (filters) => {
        setFilters(filters);
    };
    const onSpreadsheetDialogClose = () => {
        setInventorySpreadsheetUploadDialogOpen(false);
        queryClient.invalidateQueries(queryKey);
    };
    const onScanDialogClose = () => {
        setScanUploadDialogOpen(false);
        queryClient.invalidateQueries(queryKey);
    };
    const onPublishAllDialogClose = () => {
        setPublishAllDialogOpen(false);
        queryClient.invalidateQueries(queryKey);
    };

    let filteredFabrics = data?.fabrics;
    if (data && filters && Object.keys(filters).length > 0) {
        filteredFabrics = filterMaterials(data?.fabrics, filters);
    }

    let tableContent;
    if (error || (filteredFabrics && filteredFabrics.length === 0)) {
        tableContent = <EmptyState />;
    } else if (isLoading) {
        tableContent = <div className={classes.circularProgress}><CircularProgress /></div>;
    } else {
        tableContent = <FadeIn>
            <MaterialTable materials={filteredFabrics} attributeColumns={attributeColumns} extraColumns={extraColumns} />
        </FadeIn>;
    }

    return (
        <>
            <Button
                className={classes.aside}
                onClick={() => history.replace('/home')}
                color="primary"
                disableRipple
            >Home</Button>

            <div className={classes.header}>
                <Typography variant="header">Inventory Management</Typography>
                {/* <Search searchHandler={filterHandler} /> */}
            </div>

            <div className={classes.buttonBar}>
                <div>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setInventorySpreadsheetUploadDialogOpen(true)}
                    >
                        <UploadFileIcon color="primary" className={classes.buttonIcon}></UploadFileIcon>Upload Inventory
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setScanUploadDialogOpen(true)}
                    >
                        <ScannerIcon color="primary" className={classes.buttonIcon}></ScannerIcon>Upload Scans
                    </Button>
                </div>
                <div>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => setPublishAllDialogOpen(true)}
                    >
                        Publish All
                    </Button>
                    <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                            unpublishMaterials(filteredFabrics);
                        }}
                    >
                        Unpublish All
                    </Button>
                </div>
            </div>

            {
                data?.fabrics &&
                <Search searchFields={getSearchFieldsWithOptions(data.fabrics, searchFields)} searchHandler={searchHandler} />
            }
            {tableContent}

            <InventorySpreadsheetUploadDialog
                open={inventorySpreadsheetUploadDialogOpen}
                onClose={onSpreadsheetDialogClose}
            ></InventorySpreadsheetUploadDialog>
            <ScanUploadDialog
                open={scanUploadDialogOpen}
                onClose={onScanDialogClose}
            ></ScanUploadDialog>
            <PublishAllConfirmDialog
                materials={filteredFabrics}
                open={publishAllDialogOpen}
                onClose={onPublishAllDialogClose}
                publishMaterialsFunction={publishMaterials}
            ></PublishAllConfirmDialog>
        </>
    );
};