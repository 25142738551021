import qs from 'qs';
import JSZip from 'jszip';
import { api } from './client';


const readSpreadsheetData = file => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  return new Promise((resolve, reject) => {
    reader.onload = function () {
      resolve(reader.result);
    };

    reader.onerror = function () {
      reject(reader.error);
    };
  });
};

export const uploadFabricsSpreadsheetForPreview = (file) => {
  return readSpreadsheetData(file)
    .then(spreadsheetData => {
      return api.post('/inventory/preview', {
        data: spreadsheetData
      });
    });
};

export const uploadFabricsSpreadsheet = (file) => {
  return readSpreadsheetData(file)
    .then(spreadsheetData => {
      return api.post('/inventory', {
        data: spreadsheetData
      });
    });
};

export const getAllFabrics = (query, marker) => {
  const queryParams = constructQueryParams(query, marker);
  return api.get(`/fabrics?${queryParams}`).then(res => res.data);
};

export const getFabrics = (brandName, query, marker) => {
  const queryParams = constructQueryParams(query, marker);
  return api.get(`/brands/${brandName}/fabrics?${queryParams}`).then(res => res.data);
};

export const fetchMaterialURL = (brandName, fabricId, format) => {
  return api.get(`/brands/${brandName}/fabrics/${fabricId}/format/${format}`).then(res => res.data);
};

function removeHiddenFiles(files) {
  return files.filter(f => !f.name.startsWith("."));
}
async function zipFiles(name, files) {
  const zipper = new JSZip();
  files = removeHiddenFiles(files);
  for (let file of files) {
    const rootFileName = file.webkitRelativePath.split("/")[0];
    const index = file.webkitRelativePath.split("/").lastIndexOf(rootFileName);
    const path = file.webkitRelativePath.split("/").slice(index + 1).join("/");
    zipper.file(path, file);
  }
  const zippedBlob = await zipper.generateAsync({
    type: "blob",
    compression: "DEFLATE",
    compressionOptions: {
      level: 9
    }
  });

  return new File([zippedBlob], name);
}

export const uploadScans = async (files) => {
  const filesArray = Array.from(files);
  const u3mFiles = filesArray.filter(f => f.webkitRelativePath.split("/").find(p => p === "u3m"));
  const xtexFiles = filesArray.filter(f => f.webkitRelativePath.split("/").find(p => p === "xtex"));
  const u3maFiles = filesArray.filter(f => f.webkitRelativePath.split("/").find(p => p === "u3ma"));

  const body = new FormData();
  if (u3mFiles.length) {
    const zippedU3m = await zipFiles("u3m", u3mFiles);
    body.append("u3m", zippedU3m);
  }
  if (xtexFiles.length) {
    const zippedXtex = await zipFiles("xtex", xtexFiles);
    body.append("xtex", zippedXtex);
  }
  if (u3maFiles.length) {
    const zippedU3ma = await zipFiles("u3ma", u3maFiles);
    body.append("u3ma", zippedU3ma);
  }
  if (u3mFiles.length) {
    const icon = u3mFiles.find(f => f.name.includes("_BASE."));
    body.append("icon", icon);
  } else {
    const icon = xtexFiles.find(f => f.name.includes("_BASE."));
    body.append("icon", icon);
  }

  const fileName = filesArray[0].webkitRelativePath.split("/")[0];
  body.append("fileName", fileName);

  return api.post('/inventory-scans', body, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

const constructQueryParams = (query, marker) => {
  let params = {};
  if (query) {
    params["query"] = query;
  }
  if (marker !== null)
    params["marker"] = marker;

  return qs.stringify(params);
};

export const updateFabric = async (fabric) => {
  const result = await api.put(`/inventory/${encodeURIComponent(fabric.fileName)}`, fabric);
  return result.data;
};

export const updateFabrics = async (fabrics) => {
  const result = await api.put(`/inventory`, fabrics);
  return result.data;
};

export const deleteFabric = async (materialId) => {
  const result = await api.delete(`/fabrics/${materialId}`);
  return result.data;
};