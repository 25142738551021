
import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Table from '@mui/material/Table';
import InboxIcon from '@mui/icons-material/Inbox';
import TableContainer from '@mui/material/TableContainer';

import { TableHeader } from './MaterialsTable/TableHeader';

const hrv = "body1"

const useStyles = makeStyles((theme) => ({
    table: {
        // minWidth: 650,
        marginTop: "1%"
    },

    emptyState: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: "center",
        flexDirection: 'column',
        marginTop: '2%',
        color: '#a8a8a8'
    }
}));

export const EmptyState = () => {
    const classes = useStyles();
    return (
        <div className={classes.emptyState}>
            <InboxIcon />
            <Typography component="div" variant={hrv} ><div>No materials found</div></Typography>
        </div>
    )
}

// handle case where the brand has no products...
// TODO fix with table.. 
export const EmptyStateWithTable = () => {
    // todo adding sticky header gets rid of the styling....
    const classes = useStyles();
    return (
        <div>
            <TableContainer >
                <Table className={classes.table} size="small">
                    <TableHeader />
                </Table>
            </TableContainer>
            <EmptyState/>
        </div>
    );
};
