import React from 'react';
import './App.css';
import Landing from './Home/Landing';
import { JMLLibrary, MyBrandLibrary, JMLCollection } from './BrandLibrary/BrandLibrary';
import { FabricDetails } from './MaterialDetails/fabricDetails';
import Footer from './Footer/Footer';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { StyledEngineProvider, createTheme, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";
import ResponsiveDrawer from './Nav/ResponsiveDrawer';
import Contact from './Contact/Contact'
import RequestAccount from './RequestAccount/RequestAccount'

import ScrollToTop from './Common/ScrollToTop'

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { Protected } from "./Auth/Protected"
import TrainingCenter from './Training/TrainingCenter';
import { AdminProtected } from './Auth/AdminProtected';
import Admin from './Admin/Admin';

function App({ pca }) {

  let theme = createTheme(
    {
      breakpoints: {
        values: {
          xs: 0,
          sm: 640,
          md: 1000,
          lg: 1500,
          xl: 1920,
        },
      },
    })
  theme = createTheme(theme, {

    palette: {
      primary: {
        main: 'rgb(0, 156, 119)',
      },
      secondary: {
        main: '#ffffff',
      },
    },
    typography: {
      htmlFontSize: 16,
      fontFamily: "'Work Sans', sans-serif",
      header: {
        fontSize: '4rem',
        fontWeight: "bold",
        textTransform: "uppercase",
        fontFamily: "'Work Sans', sans-serif",
        [theme.breakpoints.only('md')]: {
          fontSize: "3rem",
        },
        [theme.breakpoints.only('sm')]: {
          fontSize: '2rem',
        },
        [theme.breakpoints.only('xs')]: {
          fontSize: '2rem',
        },
      },
      headline: {
        fontSize: '1.3rem',
        lineHeight: '1.1',
        fontFamily: "'Work Sans', sans-serif",
        [theme.breakpoints.only('md')]: {
          fontSize: "1rem",
        },
        [theme.breakpoints.only('sm')]: {
          fontSize: '0.8rem',
        },
        [theme.breakpoints.only('xs')]: {
          fontSize: '0.8rem',
        },
      },
      title1: {
        fontFamily: "'Work Sans', sans-serif",
        fontSize: '1.3rem'
      },
      input: {
        fontFamily: "'Work Sans', sans-serif",
        //fontSize: '1.3rem'
      },
      body1: {
        fontFamily: "'Work Sans', sans-serif",
      },
      body2: {
        fontFamily: "'Work Sans', sans-serif",
      }

    },
  });

  theme = responsiveFontSizes(theme);

  // theme.typography.fontFamily = "'Work Sans', sans-serif";

  // theme.typography.subtitle1 = {
  //   fontFamily: "'Work Sans', sans-serif",
  //   fontSize: "0.8rem",
  //   fontWeight: "normal",
  // }

  // theme.typography.h1 = {
  //   fontFamily: "'Work Sans', sans-serif",
  //   fontSize: "5rem",
  //   fontWeight: "bold",
  //   textTransform: "uppercase",
  //   [theme.breakpoints.only('md')]: {
  //     fontSize: "3.8rem",
  //   },
  //   [theme.breakpoints.only('sm')]: {
  //     fontSize: '3.7rem',
  //   },
  //   [theme.breakpoints.only('xs')]: {
  //     fontSize: '2.2rem',
  //   },
  // }

  // theme.typography.h1 = {
  //   fontFamily: "'Work Sans', sans-serif",
  //   fontSize: "3.1rem",
  //   fontWeight: "700",
  //   textTransform: "uppercase",
  //   [theme.breakpoints.only('md')]: {
  //     fontSize: "2.2rem",
  //   },
  //   [theme.breakpoints.only('sm')]: {
  //     fontSize: '2.1rem',
  //   },
  //   [theme.breakpoints.only('xs')]: {
  //     fontSize: '1.7rem',
  //   },
  // }

  // theme.typography.body1 = {
  //   fontSize: "1.9rem",
  //   fontFamily: "'Work Sans', sans-serif",
  //   [theme.breakpoints.only('lg')]: {
  //     fontSize: "1.5rem",
  //   },
  //   [theme.breakpoints.only('md')]: {
  //     fontSize: "1.2rem",
  //   },
  //   [theme.breakpoints.only('sm')]: {
  //     fontSize: '1.1rem',
  //   },
  //   [theme.breakpoints.only('xs')]: {
  //     fontSize: '1rem',
  //   },
  // }

  // theme.typography.h2 = {
  //   fontFamily: "'Work Sans', sans-serif",
  //   fontSize: "2rem",
  //   fontWeight: "600",
  //   [theme.breakpoints.only('md')]: {
  //     fontSize: "1.3rem",
  //   },
  //   [theme.breakpoints.only('sm')]: {
  //     fontSize: '1.2rem',
  //   },
  //   [theme.breakpoints.only('xs')]: {
  //     fontSize: '1.1rem',
  //   },
  // }

  // theme.typography.h3 = {
  //   fontFamily: "'Work Sans', sans-serif",
  //   fontSize: "1rem",
  //   fontWeight: "bold",
  //   [theme.breakpoints.only('sm')]: {
  //     fontSize: '0.1',
  //   },
  //   [theme.breakpoints.only('xs')]: {
  //     fontSize: '0.1rem',
  //   },
  // }

  // theme.typography.h4 = {
  //   fontFamily: "'Work Sans', sans-serif",
  //   fontSize: "0.7rem",
  //   fontWeight: "bold",
  //   lineHeight: 1,
  //   [theme.breakpoints.only('sm')]: {
  //     fontSize: '0.1',
  //   },
  //   [theme.breakpoints.only('xs')]: {
  //     fontSize: '0.1rem',
  //   },
  // }

  // theme.typography.body2 = {
  //   fontFamily: "'Work Sans', sans-serif",
  //   fontSize: "0.7rem",
  //   fontWeight: "normal",
  // }


  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Router>
          <MsalProvider instance={pca}>
            <ScrollToTop>
              <ResponsiveDrawer />

              <Switch>
                <Route path="/jml-library">
                  <Protected>
                    <JMLLibrary />
                  </Protected>
                </Route>
                <Route path="/jml-collection">
                  <Protected>
                    <JMLCollection />
                  </Protected>
                </Route>
                <Route path="/brand-library">
                  <Protected>
                    <MyBrandLibrary />
                  </Protected>
                </Route>
                <Route path="/fabric-library/:id">
                  <Protected>
                    <FabricDetails />
                  </Protected>
                </Route>
                <Route exact path="/contact-us">
                  <Contact />
                </Route>
                <Route exact path="/request">
                  <RequestAccount />
                </Route>
                <Route path="/home">
                  <Landing />
                </Route>
                <Route path="/training-center">
                  <Protected>
                    <TrainingCenter />
                  </Protected>
                </Route>
                <Route path="/admin">
                  <AdminProtected>
                    <Admin />
                  </AdminProtected>
                </Route>
                <Route exact path="/">
                  <Landing />
                </Route>
              </Switch>
              <Footer />
            </ScrollToTop>
          </MsalProvider>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
