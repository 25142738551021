
import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const useStyles = makeStyles((theme) => ({
    root: {
        // display: "flex",
        flexDirection: "column",
        // flexGrow: 0
    },
    list: {
        listStyle: "none",
        padding: 0,
    },
    grid: {
        justifyContent: "center",
        justify: "center",
        margin: "auto",
    },
    item: {
        padding: "1%",
    },
    copyrightItem: {
        padding: "1%",
        color: "#bababa"
    },
    container: {
        background: "rgba(77, 77, 77, 1)",
        color: "white",
        paddingTop: "1rem",
        fontSize: "1rem",
    },
    container2: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        background: "rgba(51, 51, 51, 1)",
        color: "white",
        paddingTop: "1%",
        paddingBottom: "1%"
    },
    
    "& a:-webkit-any-link": {
        color: "#ffffff",
    },
}))



function Footer() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.container2}>
                <Typography variant="subtitle1" className={classes.copyrightItem}> &#169; JML 2021. All rights reserved.</Typography> 
                <Typography variant="subtitle1" className={classes.item}> <Link color="inherit"  component='a' target="_blank" href="https://www.iubenda.com/privacy-policy/40560264">Privacy Policy</Link></Typography> 
                <Typography variant="subtitle1" className={classes.item}> <Link color="inherit"  component='a' target="_blank" href="https://www.iubenda.com/privacy-policy/40560264/cookie-policy">Cookie policy</Link></Typography> 
                <Typography variant="subtitle1" target="_blank" className={classes.item}> <Link color="inherit" component='a' target="_blank" href="https://www.iubenda.com/terms-and-conditions/40560264">Terms and Conditions</Link></Typography> 
            </div>
        </div>
    );
}

export default Footer;
