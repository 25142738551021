import React from 'react';
import { makeStyles } from '@mui/styles';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/styles';
import { StyledEngineProvider, adaptV4Theme, createMuiTheme } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';
import { fetchMaterialURL } from '../Api/fabricApi';
import axios from 'axios';
const FileDownload = require('js-file-download');

// should be enum
const u3m = "u3m";
const xTex = "xtex";
const u3ma = "u3ma";
// const obj = "obj"


const radioTheme = createMuiTheme(adaptV4Theme({
    // overrides: {
    //     MuiRadio: {
    //         root: {
    //             padding: 1
    //         }
    //     }
    // }
}));

const useStyles = makeStyles((theme) => ({
    formControl: {
        display: "flex",
        flexDirection: "row",
        margin: theme.spacing(0),
    },
    button: {
        fontSize: theme.typography.body2.fontSize,
        margin: theme.spacing(0, 0, 0, 0),
        minWidth: '90px',
        borderRadius: "0",
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
        },
    },
    fontSize: {
        "& span:last-child": {
            fontSize: theme.typography.body2.fontSize,
            fontFamily: theme.typography.body2.fontFamily
        }
    },
    radio: {
        '&$checked': {
            color: theme.palette.primary.main
        },
        '&:hover': {
            background: 'rgba(0, 156, 119, 0.3)',
        },
        "&.MuiRadio-colorSecondary.Mui-checked:hover": {
            background: 'rgba(0, 156, 119, 0.3)'
        },
        // '&.PrivateSwitchBase-root-50': { // this isn't working anymore....
        //     padding: '2px'
        // }
    },
    // '.MuiRadio-root': {
    //     padding: '2px'
    // },
    // '&.PrivateSwitchBase-root-52': {
    //     padding: '2px'
    // },
    checked: {},
    helperText: {
        color: '#b00'
    },
}));

export default function DownloadMaterial({ brandName, fabricId, fileTypes }) {
    const classes = useStyles();
    const [value, setValue] = React.useState('');
    const [error, setError] = React.useState(false);
    const [helperText, setHelperText] = React.useState('');
    const [isLoading, setLoading] = React.useState(false);

    const handleRadioChange = (event) => {
        setValue(event.target.value);
        setHelperText('');
        setError(false);
    };

    const fetchFile = (url) => {
        return axios({
            url: url,
            method: "GET",
            responseType: "blob" // important
        }).then(response => {
            FileDownload(response.data, `${fabricId}.zip`);
            setLoading(false);
        });
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        let format;
        if (fileTypes.has(u3m) && fileTypes.has(xTex)) {
            format = value;
            console.log(`chosen format: ${format}`);
            if (value === u3m) {
                setError(false);
            } else if (value === xTex) {
                setError(false);
            } else if (value === u3ma) {
                setError(false);
            } else {
                console.log("pick a value");
                setHelperText('Please select an option.');
                setError(true);
                return;
            }
        } else {
            format = 'obj';
        }

        try {
            setLoading(true);
            const response = await fetchMaterialURL(brandName, fabricId, format);
            await fetchFile(response.data);
        } catch (error) {
            console.log(`failed to download file: ${error}`);
        }

    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl margin="dense" component="fieldset" error={error} className={classes.formControl}>
                {fileTypes.has(u3m) && fileTypes.has(xTex) && <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={radioTheme}>
                        <RadioGroup aria-label="quiz" name="quiz" value={value} onChange={handleRadioChange}>

                            <FormControlLabel
                                value={u3m}
                                control={
                                    <Radio size={"small"} sx={{ padding: "3px" }} disableRipple classes={{ root: classes.radio, checked: classes.checked }} />}
                                label={u3m} className={classes.fontSize} />

                            <FormControlLabel
                                value={xTex}
                                control={
                                    <Radio size={"small"} sx={{ padding: "3px" }} disableRipple classes={{ root: classes.radio, checked: classes.checked }} />}
                                label={xTex} className={classes.fontSize} />

                            {
                                fileTypes.has(u3ma) && <FormControlLabel
                                    value={u3ma}
                                    control={
                                        <Radio size={"small"} sx={{ padding: "3px" }} disableRipple classes={{ root: classes.radio, checked: classes.checked }} />}
                                    label={u3ma} className={classes.fontSize} />
                            }

                        </RadioGroup>
                    </ThemeProvider>
                </StyledEngineProvider>}
                <Button type="submit" variant="contained" size={"small"} color="primary" disableRipple className={classes.button}>
                    {isLoading ? <CircularProgress size={25} color="inherit" /> : 'Download'}
                </Button>

            </FormControl>
            <FormHelperText className={classes.helperText}>{helperText}</FormHelperText>
        </form>
    );
}