import React from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

import { useHistoryState } from './useHistoryState';

const useStyles = makeStyles((theme) => ({
    form: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        border: "1px solid grey",
        padding: "2%",
        margin: "2% 0 2% 0",
        justifyConent: 'space-between',
        gap: '1%'
    },
    button: {
        fontSize: theme.typography.body2.fontSize,
        margin: theme.spacing(0, 0, 0, 0),
        padding: "15px", 
        borderRadius: "0",
        minWidth: 'unset',
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
        },
    },
    textField: {
        [`& fieldset`]: {
            borderRadius: 0,
        },
    },

}));

export const filterMaterials = (materials, filters) => {
    if (Object.keys(filters).length === 0) return true;

    let filteredMaterials = [...materials]
    Object.entries(filters).forEach(([key, value]) => {
        filteredMaterials = filteredMaterials.filter((material) => {
            if (material[key] === true || material[key] === false) {
                return material[key] === value;
            } else if (material[key].toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                return true;
            } else if (!material[key] && value === 'no-value') {
                return true;
            } else {
                return false;
            }
        })
    })
    return filteredMaterials
}

const capitalize = (s) => {
    return s.slice(0, 1).toUpperCase() + s.slice(1);
};
export const getSearchFieldsWithOptions = (materials, searchFields) => {
    const searchFieldsWithOptions = [];

    for (let searchField of searchFields) {
        const options = Object.keys(materials.reduce((map, material) => {
            if (material[searchField.key] === undefined) {
                map['No value'] = true;
            } else if (material[searchField.key] === true) {
                map[capitalize(searchField.key)] = true;
            } else if (material[searchField.key] === false) {
                map[`Not ${searchField.key}`] = true;
            } else if (material[searchField.key]) {
                map[material[searchField.key]] = true;
            }
            return map;
        }, {}))
        .sort((a, b) => {
            if (a === 'No value') {
                return -1;
            }
            if (b === 'No value') {
                return 1;
            }
            return a > b ? 1 : -1;
        });

        searchFieldsWithOptions.push({
            ...searchField,
            options
        });
    }
    return searchFieldsWithOptions;
};

export const Search = ({ searchFields, searchHandler }) => {
    const classes = useStyles();
    const initialValues = searchFields.reduce((valueSet, field) => {
        valueSet[field.key] = '';
        return valueSet;
    }, {});
    initialValues.brandItemCode = '';
    // eslint-disable-next-line
    const [state, setState] = useHistoryState('searchFields', initialValues);
    

    const reset = () => {
        const blankValues = searchFields.reduce((valueSet, field) => {
            valueSet[field.key] = '';
            return valueSet;
        }, {});
        blankValues.brandItemCode = '';
        setState(blankValues);
        search(blankValues);
    };

    const handleChange = (value, key) => {
        setState({
            ...state,
            [key]: value
        });
    };

    const handleSubmit = (event, key) => {
        event.preventDefault();
        search(state);
    }

    const search = (searchFieldState) => {
        const query = searchFields.reduce((q, field) => {
            if (state[field.key]) {
                if (field.type === "string") {
                    q[field.key] = searchFieldState[field.key];
                } else {
                    q[field.key] = searchFieldState[field.key].toLowerCase() === field.label.toLowerCase()
                }
            }
            return q;
        }, {});
        if (searchFieldState.brandItemCode) {
            query['brandItemCode'] = searchFieldState.brandItemCode;
        }
        searchHandler(query)
    };

    return (
        <div>
            <form onSubmit={handleSubmit} noValidate className={classes.form} autoComplete="off">

            <TextField
                id={'brandItemCode'}
                key={'brandItemCode'}
                label={'Brand Item Code'}
                value={state['brandItemCode']}
                onChange={(event) => handleChange(event.target.value, 'brandItemCode')}
                fullWidth
                className={classes.textField}
            >
            </TextField>

            {searchFields.map((field) => {
               return( <TextField
                    id={field.key}
                    key={field.key}
                    select
                    SelectProps={{
                        MenuProps: {
                            style: {
                                zIndex: 3201, // Must be greater than header z-index, which is 3200
                                maxHeight: '100%',
                            },
                        }
                    }}
                    label={field.label}
                    value={state[field.key]}
                    onChange={(event) => handleChange(event.target.value, field.key)}
                    fullWidth
                    className={classes.textField}
                >
                    {field.options.map((option) => (
                        <MenuItem key={option} value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField> )
            })}
                <Button 
                    variant="outlined" 
                    size="large" 
                    className={classes.button} 
                    onClick={reset}
                >Reset</Button>
                <Button type="submit" variant="contained" size={"large"} color="primary" disableRipple className={classes.button}>
                    Search
                </Button>
            </form>

        </div>
    )
}