
import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { TableRow } from '@mui/material';
import { TableHeader } from './TableHeader';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import TablePagination from '@mui/material/TablePagination';
import { useHistoryState } from '../../Common/useHistoryState';
import { ImageLoader } from '../../Common/ImageLoader.js'
const EMPTY_PLACEHOLDER = "N/A"

// todo factor out the image loader and background
const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 650,
        marginTop: "1%"
    },
    thumbnail: {
        // boxShadow: "0 5px 5px rgba(0,0,0,0.4)",
        '-webkit-filter': 'drop-shadow(0 5px 1px rgba(0,0,0,0.5))',
        filter: 'drop-shadow(0 5px 1px rgba(0,0,0,0.5))'
    },
    imgBackground: {
        display: 'flex',
        alignItems: 'center',
        height: "100px",
        width: '115px',
        //padding: "10px 16px",
        transition: '0.2s',
        overflow: 'hidden',
        backgroundColor: '#d8e0e3',
        boxShadow: 'inset 3em 2em 6em #9aa5a9',
        '&:hover': {
            transform: 'scale(1.05)',
        },
        justifyContent:'center'
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
}));

const StyledTableRow = withStyles((theme) => ({
    root: {
        height: "100px",
        fontFamily: "'Work Sans', sans-serif",
    }
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
    root: {
        margin: "10px 16px",
        fontFamily: "'Work Sans', sans-serif",
    }
}))(TableCell);

const StyledTableContainer = withStyles(() => ({
    root: {
        overflowX: "visible",
        fontFamily: "'Work Sans', sans-serif",
    }
}))(TableContainer)

const MaterialsTable = ({ materials, attributeColumns, extraColumns }) => {
    const classes = useStyles();
    const [page, setPage] = useHistoryState('page', 0);
    const [rowsPerPage, setRowsPerPage] = useHistoryState('rowsPerPage', 10);
    const hrv = "body1"
    const brv = "body2"

    const handleChangePage = async (event, newPage) => {
        // if there's more data to load, make another request
        // only make a request when the page change goes forward
        // if (newPage > page && data.marker !== "") {
        //     setNextPageToken(data.marker)
        // }
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    }

    useEffect(() => {
        if (page * rowsPerPage > materials.length) {
            setPage(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materials]);

    return (
        <div>
            <StyledTableContainer >
                <Table className={classes.table} size="small" aria-label="simple table" >
                    <TableHeader attributeColumns={attributeColumns} extraColumns={extraColumns} />
                    <TableBody>
                        {materials && materials.length > 0 && (materials.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)).map((row) => (
                            <StyledTableRow className={classes.row} key={row.id + Date.now()}>
                                <StyledTableCell align="right" style={{ width: 200 }}>
                                    <Link color="inherit" component={RouterLink} to={
                                        {
                                            pathname: "/fabric-library/" + row.fileName,
                                            state: { fabricDetails: row }
                                        }
                                    }>
                                        <div className={classes.imgBackground} >
                                            <ImageLoader src={row.url} className={classes.thumbnail} alt={row.materialName} width="115" height="110"/>
                                        </div>

                                    </Link>
                                </StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    <Typography variant={hrv}>
                                        <Link color="primary" component={RouterLink} to={
                                            {
                                                pathname: "/fabric-library/" + row.fileName,
                                                state: { fabricDetails: row }
                                            }
                                        }>
                                            {row.brandItemCode}
                                        </Link>
                                    </Typography>
                                </StyledTableCell>
                                {
                                    attributeColumns?.map(c => {
                                        if (row[c.attribute]?.isArray && row[c.attribute].isArray()) {
                                            return (
                                                <StyledTableCell align="left" key={c.header + row.id}>
                                                    <Typography variant={brv}>
                                                        {(row[c.attribute] && row[c.attribute][0]) || EMPTY_PLACEHOLDER}
                                                    </Typography>
                                                </StyledTableCell>
                                            );
                                        } else {
                                            return (
                                                <StyledTableCell align="left" key={c.header + row.id}>
                                                    <Typography variant={brv}>
                                                        {row[c.attribute] || EMPTY_PLACEHOLDER}
                                                    </Typography>
                                                </StyledTableCell>
                                            );
                                        }
                                    })
                                }
                                {
                                    extraColumns?.map(c => 
                                        <StyledTableCell align="left" key={c.header + row.id}>
                                            {c.render(row)}
                                        </StyledTableCell>
                                    )
                                }
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
            <TablePagination
                rowsPerPageOptions={[10, 20, 50]}
                count={materials?.length || 1}
                rowsPerPage={rowsPerPage}
                page={materials.length < page * rowsPerPage ? 0 : page}
                component="div"
                SelectProps={{
                    inputProps: { 'aria-label': 'rows per page' },
                    native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={() => `Page ${page + 1} of ${Math.ceil(materials.length / rowsPerPage)}`}
            />
        </div>
    );
};
export default MaterialsTable;