import { acquireToken } from './tokenUtil';
import { apiConfig } from './apiConfig';
import axios from 'axios'

export const api = axios.create({
    baseURL: apiConfig.api
})

api.interceptors.request.use(
    async (config) => {
        const token = (await acquireToken()).accessToken;
        const auth = token ? `Bearer ${token}` : ''; // TODO err throw an error if we don't have an auth token 
    //   console.log(`token: ${auth} `)

        config.headers.common['Authorization'] = auth;
        return config;
    },
    (error) => Promise.reject(error),
);