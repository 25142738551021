import { makeStyles } from '@mui/styles';

export const contentStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '400px',
        overflowY: 'scroll'
    },
    uploadIcon: {
        width: '200px',
        height: '200px',
        color: "#ccc",
        cursor: 'pointer',
        '&$complete': {
            color: theme.palette.primary.main
        }
    },
    errorIcon: {
        width: '200px',
        height: '200px',
        color: theme.palette.error.main
    },
    complete: {},
    hiddenInput: {
        display: 'none'
    },
    previewLink: {
      cursor: 'pointer'
    }
}));