import React, { createRef, useState } from 'react';
import Button from '@mui/material/Button';
import BasicDialog from './BasicDialog';
import CircularProgress from '@mui/material/CircularProgress';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import ArrowRight from '@mui/icons-material/ArrowRight';
import ErrorIcon from '@mui/icons-material/Error';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { uploadFabricsSpreadsheet, uploadFabricsSpreadsheetForPreview } from '../Api/fabricApi';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { contentStyles } from './dialogStyles';

export default function InventorySpreadsheetUploadDialog(props) {
  const contentClasses = contentStyles();
  const { open, onClose } = props;
  const [ file, setFile ] = useState(undefined);
  const [ error, setError ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ preview, setPreview ] = useState(null);

  const reset = () => {
    setFile(undefined);
    setError(null);
    setCurrentStep(0);
    setLoading(false);
    setPreview(null);
  };

  const handleClose = () => {
    reset();
    onClose();
  };

  const fileInputRef = createRef();
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  const selectFile = (file) => {
    if (file.type.indexOf("excel") >= 0) {
      setFile(file);
      setError(null);
    } else {
      setError("Invalid file type");
    }
  };
  const previewFile = (file) => {
    setLoading(true);
    setCurrentStep(1);
    return uploadFabricsSpreadsheetForPreview(file)
      .then(result => {
        setPreview(result.data)
      })
      .catch(e => {
        const errorMessages = e.response.data?.errors.map(m => <div>{m}</div>);
        setError(errorMessages || "Error uploading file");
        setCurrentStep(2);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const importFile = (file) => {
    setLoading(true);
    setCurrentStep(2);
    return uploadFabricsSpreadsheet(file)
      .then(result => {
        handleClose();
      })
      .catch(e => {
        setError("Error uploading file")
      })
      .finally(() => {
        setLoading(false);
      })
  };
  const backToFileUpload = () => {
    reset();
  };
  const [ showPreview, setShowPreview ] = useState(false);
  const [ previewRows, setPreviewRows ] = useState(null);
  const toggleNewFabricPreview = () => {
    setShowPreview(true);

    setPreviewRows(preview.newFabrics);
  }
  const toggleReplaceFabricPreview = () => {
    setShowPreview(true);

    setPreviewRows(preview.differentFabrics);
  };
  
  const steps = [
    {
      content:
        <div className={contentClasses.container}>
          {
            file ? 
              <React.Fragment>
                  <CheckCircleOutlineIcon className={`${contentClasses.uploadIcon} ${contentClasses.complete}`}></CheckCircleOutlineIcon>
                  <strong>{file.name}</strong> selected
              </React.Fragment>
              : 
              <React.Fragment>
                  <UploadFileIcon className={contentClasses.uploadIcon} onClick={triggerFileInput}></UploadFileIcon>
                  <Button variant="outlined" onClick={triggerFileInput}>Choose a spreadsheet to upload</Button>
                  <Typography color="error">{error}</Typography>
              </React.Fragment>
                
          }
          <input className={contentClasses.hiddenInput} type="file" ref={fileInputRef} onChange={(e) => selectFile(e.target.files[0])}/>
        </div>,
      actions:
        <React.Fragment>
          <Button autoFocus onClick={() => previewFile(file)} variant="contained" disabled={!file}>
            Next <ArrowRight></ArrowRight>
          </Button>
        </React.Fragment>
    },
    {
      content:
        <div className={contentClasses.container}>
          {
            loading ?
              <React.Fragment>
                <CircularProgress color="primary" style={{width: 140, height: 140, margin: 25}}></CircularProgress>
                Rendering preview...
              </React.Fragment>
              :
              <React.Fragment>
                <div>
                  Importing this spreadsheet will add <Link onClick={toggleNewFabricPreview} className={contentClasses.previewLink}>{preview?.newFabrics.length}</Link> new fabrics and replace <Link onClick={toggleReplaceFabricPreview} className={contentClasses.previewLink}>{preview?.differentFabrics.length}</Link> fabrics
                </div>
                { 
                  showPreview &&
                  <div>
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 400 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell><strong>Brand</strong></TableCell>
                            <TableCell><strong>Brand Item Code</strong></TableCell>
                            <TableCell><strong>Supplier Article Number</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {previewRows.map((row) => (
                            <TableRow
                              key={row.itemCode}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.brand}
                              </TableCell>
                              <TableCell>{row.brandItemCode}</TableCell>
                              <TableCell>{row.supplierArticleNumber}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                }
              </React.Fragment>
          }
        </div>
      ,
      actions:
          <React.Fragment>
            <Button onClick={backToFileUpload} variant="outlined">
              <ArrowLeft></ArrowLeft> Back
            </Button>
            <Button autoFocus onClick={() => importFile(file)} variant="contained" disabled={loading}>
              Import
            </Button>
          </React.Fragment>
    },
    {
      content:
          <div className={contentClasses.container}>
            {
              loading &&
              <React.Fragment>
                <CircularProgress color="primary" style={{width: 140, height: 140, margin: 25}}></CircularProgress>
                Importing...
              </React.Fragment>
            }
            {
              error &&
              <React.Fragment>
                <ErrorIcon className={contentClasses.errorIcon}></ErrorIcon>
                <Typography color="error">{error}</Typography>
              </React.Fragment>
            }
          </div>,
      actions: 
          <React.Fragment>
            <Button autoFocus onClick={backToFileUpload} variant="outlined">
              <ArrowLeft></ArrowLeft> Back
            </Button>
          </React.Fragment>
    }
  ];

  const [ currentStep, setCurrentStep ] = useState(0);

  return (
    <BasicDialog
      open={open}
      onClose={handleClose}
      title="Upload Inventory Spreadsheet"
      content={steps[currentStep].content}
      actions={steps[currentStep].actions}
    ></BasicDialog>
  );
}