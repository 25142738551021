import React from 'react';
import trainingCenterBanner from '../assets_new/training-center.jpg';
import { CoverBanner } from '../Common/CoverItem';
import { Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import FadeIn from 'react-fade-in';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import baseStyles from '../Common/styles';

const useStyles = makeStyles((theme) => ({
    ...baseStyles(theme),
    banner: {
        height: '200px',
        overflow: 'hidden'
    },
    // videoGallery: {
    //     width: '990px'
    // },
    video: {
        display: 'block',
        width: '320px',
        height: '240px',
    },
    videoContainer: {
        display: 'inline-block',
        margin: '5px',
        textAlign: 'center'
    },
    contact: {
        margin: 'auto',
        textAlign: 'center'
    }
}));

export default function TrainingCenter() {
    const classes = useStyles();

    return (
        <FadeIn>
            <div className={classes.root}>
                <div className={classes.banner}>
                    <CoverBanner image={trainingCenterBanner} title='TRAINING CENTER'
                        description={<>
                            <Typography paragraph>Become an expert at using JML's material libraries!</Typography>
                        </>}
                    />
                </div>
                <div className={classes.section}>
                    <Typography variant="header" color="primary">Getting Started</Typography>
                    <Typography paragraph>Learn how to import our digital fabrics into your garment design software</Typography>
                    <div className={classes.videoGallery}>
                        <div className={classes.videoContainer}>
                            <video controls className={classes.video}>
                                <source src="https://jmlapparelstorage.blob.core.windows.net/media/optitex-demo.mp4" type="video/mp4" />
                            </video>
                            <Typography variant="caption">Browzwear Tutorial</Typography>
                        </div>
                        <div className={classes.videoContainer}>
                            <video controls className={classes.video}>
                                <source src="https://jmlapparelstorage.blob.core.windows.net/media/optitex-demo.mp4" type="video/mp4" />
                            </video>
                            <Typography variant="caption">Downloading &#38; Importing 3D Trims into Optitex</Typography>
                        </div>
                        <div className={classes.videoContainer}>
                            <video controls className={classes.video}>
                                <source src="https://jmlapparelstorage.blob.core.windows.net/media/login-demo.mp4" type="video/mp4" />
                            </video>
                            <Typography variant="caption">JML 3D portal logging in</Typography>
                        </div>
                        <div className={classes.videoContainer}>
                            <video controls className={classes.video}>
                                <source src="https://jmlapparelstorage.blob.core.windows.net/media/clo3d-demo.mp4" type="video/mp4" />
                            </video>
                            <Typography variant="caption">Downloading &#38; Importing 3D Trims into CLO3D</Typography>
                        </div>
                    </div>
                </div>
                <div className={classes.section}>
                    <Typography className={classes.contact} variant="h5" color="primary">Need more help? <Link color="primary" component={RouterLink} to="/contact-us">Contact us </Link></Typography>
                </div>
            </div>
        </FadeIn>
    );
}