
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import digital from '../assets_new/digital-sidebar.jpg';
import FadeIn from 'react-fade-in'
import { Formik, Form, Field } from 'formik';
import { Button, LinearProgress } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/lab/Alert';
import { TextField } from 'formik-material-ui';
import { requestAccount } from '../Api/requestAccountApi';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '90vh',
    display: 'flex',
    maxWidth: "100%",
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  introContainer: {
    maxWidth: "90%",
    margin: "auto",
    marginBottom: "2%",
  },
  sidebarImg: {
    width: '30%',
    height: 'auto',
    objectFit: 'cover'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    width: '40%',
    margin: 'auto',
    backgroundColor: 'white',
    padding: '30px'
  },
  title: {
    marginBottom: "4%"
  },
  subtext: {
    marginBottom: '10px'
  },
  body: {
    lineHeight: 1.5,
    fontSize: '1rem'
  },
  link: {
    color: theme.palette.primary.main,
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  formRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  formField: {
    margin: ' 0 10px 0 0',
  },
  submit: {
    margin: '4% 0',
    borderRadius: '0',
    // fontSize: theme.typography.body1.fontSize,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: "#fff",
    },
  },
}));

const Basic = ({ classes, handleSuccessToast, handleErrorToast }) => (
  <Formik
    initialValues={{
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      companyName: '',
      brandName: '',
      position: '',
      designSoftware: '',
    }}
    validate={values => {
      const errors = {};
      if (!values.email) {
        errors.email = 'Required';
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
      ) {
        errors.email = 'Invalid email address';
      }
      if (!values.firstName) {
        errors.firstName = 'Required'
      }
      if (!values.lastName) {
        errors.lastName = 'Required'
      }
      if (!values.phone) {
        errors.phone = 'Required'
      }
      if (!values.companyName) {
        errors.companyName = 'Required'
      }
      if (!values.brandName) {
        errors.brandName = 'Required'
      }
      if (!values.position) {
        errors.position = 'Required'
      }
      if (!values.designSoftware) {
        errors.designSoftware = 'Required'
      }
      return errors;
    }}
    onSubmit={(values, { setSubmitting, resetForm }) => {
      requestAccount(values).then(res => {
        setSubmitting(false);
        handleSuccessToast()
        resetForm()
      })
        .catch(error => {
          setSubmitting(false);
          handleErrorToast()
        })
    }}
  >
    {({ submitForm, isSubmitting }) => (
      <Form className={classes.form}>
        <div className={classes.formRow}>
          <Field
            component={TextField}
            name="firstName"
            label="First Name *"
            className={classes.formField}
          />
          <Field
            component={TextField}
            name="lastName"
            label="Last Name *"
            className={classes.formField}
          />
        </div>
        <div className={classes.formRow}>
          <Field
            component={TextField}
            name="email"
            type="email"
            label="Email *"
            className={classes.formField}
          />
          <Field
            component={TextField}
            name="phone"
            // type="phone"
            label="Phone Number *"
            className={classes.formField}
          />
        </div>
        <Field
          component={TextField}
          name="companyName"
          label="Company Name *"
          className={classes.formField}
        />
        <Field
          component={TextField}
          name="brandName"
          label="Brand Name *"
          className={classes.formField}
        />
        <Field
          component={TextField}
          name="position"
          label="Position *"
          className={classes.formField}
        />
        <Field
          component={TextField}
          label="Which 3D design software are you using? *"
          name="designSoftware"
          placeholder="Browzwear, CLO3D, Optitex, etc"
          className={classes.formField}
        />
        {isSubmitting && <LinearProgress />}
        <Button
          variant="contained"
          color="primary"
          disabled={isSubmitting}
          onClick={submitForm}
          className={classes.submit}
          disableRipple
        >
          Submit Request
        </Button>
      </Form>
    )}
  </Formik>
);

function RequestAccount() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [severity, setSeverity] = useState("info");
  const [message, setMessage] = useState("");


  const handleSuccessToast = () => {
    setSeverity('success')
    setMessage('Success')
    setOpen(true);
  };

  const handleErrorToast = () => {
    setSeverity('error')
    setMessage('Error sending request')
    setOpen(true);
  };


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <FadeIn>
      <div className={classes.root}>
        <img src={digital} alt="" className={classes.sidebarImg} />
        <div className={classes.contentContainer}>
          <Typography
            className={classes.title}
            component="div"
            color="primary"
            variant="h1">
            REQUEST ACCOUNT
          </Typography>

          <Typography
            variant="body2"
            className={classes.subtext}>
            Please tell us a little bit about yourself and a member of our team will reach out with next steps.
          </Typography>

          <Basic
            classes={classes}
            handleSuccessToast={handleSuccessToast}
            handleErrorToast={handleErrorToast} />

          <div>Our <a href="https://www.iubenda.com/privacy-policy/40560264" target="_blank" rel="noopener noreferrer" className={classes.link}>Privacy Policy</a> describes how we process your personal data.</div>
        </div>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={handleClose}
          severity={severity}
        >
          {message}
        </MuiAlert>
      </Snackbar>
    </FadeIn>
  );
}

export default RequestAccount;
