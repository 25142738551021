
import React from 'react';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import clsx from 'clsx';
import { useHistory } from "react-router-dom";



const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '2rem'
    },
    content: {

    },
    contentLeft: {
        textAlign: 'left',
        position: 'absolute',
        color: "white",
        zIndex: 1,
        top: 0,
        width: '55%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'start',
        flexDirection: 'column',
        padding: '25px',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    contentCenter: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '0px',
        color: "white",
        zIndex: 1,
        height: '100%',
        width: '100%',
        margin: 'auto',
        textTransform: 'uppercase',
        "& > *": {
            margin: '5px'
        }
    },
    card: {
        position: 'relative',
        zIndex: 0,
        cursor: 'pointer',
        overflow: 'hidden'
    },
    button: {
        fontSize: "1.3rem",
        borderRadius: "0",
        padding: 10,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
        },
    },
}));

// TODO button routing... 
export const CoverBanner = ({ image, title, description, buttonText, query }) => {
    let history = useHistory();
    const classes = useStyles();
    return (
        <div className={classes.card} style={{ maxHeight: 600 }} onClick={() => history.push({ pathname: '/jml-collection', state: { query: query, title: title } })}>
            <img src={image} width="1920px" height="643px" style={{ objectFit: 'cover', objectPosition: '60% 50%' }} alt="" />
            <div className={clsx(classes.content, classes.contentLeft)}>
                <Typography variant="header" className={classes.title}>{title}</Typography>
                <div>{description}</div>
            </div>
        </div>
    );
};



const getCategoryTitle = (title) => {
    return (
        <>
            {/* <Typography variant="overline" >
               2023 Trims for
            </Typography> */}
            <Typography component="div" variant="title1" gutterBottom>{title} Apparel</Typography>
        </>
    );
};

const useCoverCardStyles = makeStyles((theme) => ({
    smallBtn: {
        fontSize: theme.typography.body2.fontSize,
        borderRadius: "0",
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: "#fff",
        },
    },
    card: {
        // height: "600px",
        // width: "100vw",
        background: "black",
        overflow: "hidden",
    },
    img: {
        objectPosition: '50% 0%',
        objectFit: "cover",
        opacity: "0.4",
    }

}));

export const CoverCard = ({ image, title, buttonText, query }) => {
    let history = useHistory();
    const classes = useStyles();
    const coverClasses = useCoverCardStyles();
    return (
        <div className={clsx(classes.card, coverClasses.card)} style={{ maxHeight: 425 }}>
            <img src={image} width="950" height="643" alt="" className={coverClasses.img} />
            <div className={clsx(classes.content, classes.contentCenter)}>
                <Typography gutterBottom>{getCategoryTitle(title)}</Typography>
                <Button
                    variant="outlined"
                    color="secondary"
                    disableRipple
                    size="large"
                    className={clsx(coverClasses.smallBtn)}
                    onClick={() => history.push({ pathname: '/jml-collection', state: { query: query, title: `${title} Apparel Trims` } })}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};
