import { getSession } from "../Api/sessionApi";
import { useQuery } from "react-query";
import { useMsal } from '@azure/msal-react';

export const useSession = () => {
    const msal = useMsal();
    const account = msal.accounts[0];

    const { data: session } = useQuery(['session', account], async () => {
        const session = await getSession();
        return {
            ...msal,
            user: session.user,
            groups: session.groups
        };
    });
    return session;
};
