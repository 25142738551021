export const isAdmin = (session) => {
    if (!session) {
        return false;
    }
    return session.user.role === 'ADMIN' || session.user.role === 'SUPER_ADMIN';
};

export const isSuperAdmin = (session) => {
    if (!session) {
        return false;
    }
    return session.user.role === 'SUPER_ADMIN';
};

export const isBrowseAndDownload = (session) => {
    if (!session) {
        return false;
    }

    return session.user.role === 'USER' ||
        session.user.role === 'ADMIN' ||
        session.user.role === 'SUPER_ADMIN';
};
