import React from 'react';
import { Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { CoverBanner, CoverCard } from '../Common/CoverItem';
import { useQuery } from 'react-query';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';


import productBanner from '../assets_new/3d_portal_mainpage_top_banner.jpg';
import category1 from '../assets_new/prd-cat-md-1.jpg';
import category2 from '../assets_new/prd-cat-md-2.jpg';
import category3 from '../assets_new/prd-cat-md-3.jpeg';
import category4 from '../assets_new/prd-cat-md-4.jpg';
import FadeIn from 'react-fade-in';
import CircularProgress from '@mui/material/CircularProgress';
import * as api from '../Api/fabricApi.js';

import ProductThumbnail from '../Common/ProductThumbnail';
import { EmptyState } from '../Common/EmptyState';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';
import Button from '@mui/material/Button';
import { useHistory } from "react-router-dom";


// import withWidth, { isWidthUp } from '@mui/material/withWidth';
import baseStyles from '../Common/styles';

const useStyles = makeStyles((theme) => ({
    ...baseStyles(theme),
    sectionHeader: {
        margin: '30px auto',
        width: '100%',
    },
    itemList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '1%',
    },
    hr: {
        height: '5px',
        width: "45px",
        backgroundColor: theme.palette.primary.main,
        margin: '10px auto 10px',
    },
    categories: {
        marginTop: '5px'
    },
    featuredItems: {
        marginTop: '20px'
    },
    gridList: {
        flexWrap: 'nowrap',
        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)',
    },
    aside: {
        margin: '20px auto',
    },
    sectionTitle: {
        margin: 'auto',
        zindex: 2
    },
    sectionCat: {
        marginTop: '8px'
    }
}));

const useFeaturedItemStyles = makeStyles((theme) => ({
    itemDetails: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'left',
        margin: '7px 0 7px'
    }
}));
const FeaturedItem = ({ item }) => {
    const classes = useFeaturedItemStyles();
    return (
        <div>
            <Link color="inherit" component={RouterLink} to={
                {
                    pathname: "/fabric-library/" + item.fileName,
                    state: { fabricDetails: item }
                }
            }>
                <ProductThumbnail image={item.url} height="250" width="250" />
                <div className={classes.itemDetails}>
                    <Typography variant="subtitle2" color="primary">{item.productCategory}</Typography>
                    <Typography variant="body1" >{item.brandItemCode}</Typography>
                </div>
            </Link>
        </div>
    );
};

const getFeaturedItems = (items) => {
    return items.slice(0, 5).map(item => { // TODO what if there are fewer than 5 returned?
        return <ImageListItem style={{ height: "100%" }} key={item.name}><FeaturedItem item={item} /></ImageListItem>;
    });
};

const Landing = (props) => {
    const classes = useStyles();
    let history = useHistory();

    // TODO supposed to load mybrand 
    const { isLoading, error, data } = useQuery(['myBrand', null, null], ({ queryKey }) => api.getFabrics(queryKey[0], queryKey[1], queryKey[2]));

    return (
        <FadeIn>
            <div className={classes.root}>
                <CoverBanner image={productBanner} title='2027 JML Collection'
                    description={<>
                        <Typography variant="headline" paragraph>In our 2027 product collection, we designed 200 product varieties using recycled materials with stretch and anti-microbial properties.</Typography>
                        <Typography variant="headline" paragraph>We have organized our products according to applications: active lifestyle, outdoor and intimate.</Typography>
                    </>}
                    buttonText='VIEW ALL'
                    query={{ collection1: '2027 Collections' }}
                />

                <div className={classes.sectionCat}>
                    <div className={clsx(classes.itemList, classes.categories)}>
                        <CoverCard image={category1} title='2027 Active Trims' buttonText="View Trims" query={{ collection1: '2027 Collections', collection2: '2027 Active' }} />
                        <CoverCard image={category2} title='2027 Lifestyle Trims' buttonText="View Trims" query={{ collection1: '2027 Collections', collection2: '2027 Lifestyle' }} />
                    </div>
                    <div className={clsx(classes.itemList, classes.categories)}>
                        <CoverCard image={category3} title='2027 Outdoor Trims' buttonText="View Trims" query={{ collection1: '2027 Collections', collection2: '2027 Outdoor' }} />
                        <CoverCard image={category4} title='2027 Intimate Trims' buttonText="View Trims" query={{ collection1: '2027 Collections', collection2: '2027 Intimates' }} />
                    </div>
                </div>
                <div className={classes.section}>
                    <Button
                        onClick={() => history.push({ pathname: '/brand-library' })}
                        className={classes.sectionHeader}
                        color="primary"
                        disableRipple>
                        <div>
                            <Typography variant="header" color="primary">Your Brand Library
                            </Typography>
                            <div className={classes.hr}></div>
                        </div>
                    </Button>

                    {error && <EmptyState />}
                    {isLoading && !error && <div className={classes.circularProgress}><CircularProgress /></div>}
                    {!error && !isLoading && data?.fabrics && data.fabrics.length === 0 ? <EmptyState />
                        : !isLoading && !error && <FadeIn>
                            <ImageList variant="quilted" className={classes.gridList} cols={5} gap={10}>
                                {getFeaturedItems(data.fabrics)}
                            </ImageList>
                            <Button
                                className={classes.aside}
                                onClick={() => history.push({ pathname: '/brand-library' })}
                                color="primary"
                                disableRipple
                            // startIcon={<ArrowBackIosIcon fontSize="small"/>}
                            >View All</Button>
                        </FadeIn>}


                </div>
            </div>
        </FadeIn>
    );
};

export default Landing;