import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';


import logo from '../assets_new/logo-svg.svg';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import { useMsal } from '@azure/msal-react';
import { handleLogin } from '../Auth/AuthHandlers'

import qualityOS from '../assets_new/frontpage/oeko-tex-logo.png'
import qualityBlue from '../assets_new/frontpage/bluesign-logo.png'
import qualityGRS from '../assets_new/frontpage/grs-logo.png'
import higgLogo from '../assets_new/frontpage/higg-index-logo.png'

const useStyles = makeStyles((theme) => ({
    root: {
      minHeight: '90vh',
      textTransform: 'uppercase',
      textAlign: 'center',
    },
    container: {
      minHeight: '90vh',
      display: "flex",
      width: "40%",
      margin: 'auto',
      justifyContent: "center",
      alignContent: 'center',
      flexDirection: "column",
      [theme.breakpoints.up('lg')]: {
        width: "30%"
    },
    },
    logo: {
      width: "50%",
      height: 'auto',
      margin: '0 auto',
    },
    titleContainer: {
      margin: theme.spacing(4, 0, 4, 0),
    },
    title: {
      fontWeight: "normal",
    },
    loginButton: {
      fontSize: theme.typography.body2.fontSize,
      margin: theme.spacing(0, 0, 1, 0),
      borderRadius: "0",
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: "#fff",
      },
    },
    contactUs: {
      margin: theme.spacing(0.5, 0, 8, 0),
    },
    "& a:-webkit-any-link": {
      color: theme.palette.primary.main,
    },
    qualityAccredationSection: {
      margin: theme.spacing(3, 0, 2, 0),
    },
    qualityImages: {
      disply: 'flex',
      flexDirection: 'row',
    },
    img: {
      objectFit: "cover",
      width: "20%",
      margin: theme.spacing(0, 1, 0, 1),
    },
    section: {
      marginBottom: "1%",
      backgroundColor: "white",
    },
    progress: {
      display: 'flex',
      justifyContent: 'center',
      margin: '5%'
    },
  }));

function Login() {
    const classes = useStyles();
    const { instance } = useMsal();
    return (
        <div className={classes.container}>
            <img src={logo} className={classes.logo} alt="JML logo" />
            <div className={classes.titleContainer}>
                <Typography className={classes.title} variant="h2">3D Trims Library</Typography>
                <Typography variant="subtitle1">Compatible with BROWZWEAR, CLO, &#38; OPTITEX</Typography>
            </div>
            <Button variant="contained"  disableRipple className={classes.loginButton} onClick={() => handleLogin(instance)}>Log in</Button>

            <div className={classes.contactUs}>
                <Typography variant="body2">DON'T HAVE A LOGIN?<Link component={RouterLink} to="/request"> REQUEST AN ACCOUNT </Link></Typography>
            </div>
            <div className={classes.qualityAccredationSection}>
                <Typography variant="body2">Our Quality and Sustainability Accredations
                </Typography>
                <hr />
                <div className={classes.qualityImages}>
                    <img src={higgLogo} className={classes.img} alt="" />
                    <img src={qualityBlue} className={classes.img} alt="" />
                    <img src={qualityOS} className={classes.img} alt="" />
                    <img src={qualityGRS} className={classes.img} alt="" />
                </div>
            </div>
        </div>
    );
}

export default Login;
