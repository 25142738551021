import React, { useState } from 'react';
import { getUsers, updateUser } from "../Api/userApi";
import { useQuery, useQueryClient } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import BasicDialog from './BasicDialog';
import { isSuperAdmin } from '../Auth/Authorization';
import { useSession } from '../Auth/SessionHooks';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
}
  
TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const UserTable = ({isLoading, error, users, page, setPage}) => {
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [ changingUser, setChangingUser ] = useState(null);
    const session = useSession();
    const queryClient = useQueryClient();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRoleChange = async (user, newRole) => {
        setChangingUser({
            ...user,
            oldRole: user.role,
            newRole: newRole
        });
    };
    const cancelChange = async () => {
        setChangingUser(null);
    };
    const confirmChange = async () => {
        await updateUser({
            ...changingUser,
            role: changingUser.newRole
        });
        queryClient.invalidateQueries(['users']);
        setChangingUser(null);
    };

    const RoleSelect = ({ user, onRoleChange }) => {
        const hasSuperAdminRole = isSuperAdmin(session);
        const roleOptions = [ 'USER', 'BROWSE_ONLY' ];
        if (hasSuperAdminRole) {
            roleOptions.push('ADMIN');
        }

        if (roleOptions.includes(user.role) || user.role === 'DEFAULT') {
            return (
                <Select
                    size="small"
                    value={user.role || "USER"}
                    onChange={(e) => onRoleChange(user, e.target.value)}
                >
                    <MenuItem key='default' value="DEFAULT">Default (USER)</MenuItem>
                    {
                        roleOptions.map(o => (
                            <MenuItem key={o} value={o}>{o}</MenuItem>
                        ))
                    }
                </Select>
            );
        } else {
            return (
                <span>{user.role}</span>
            );
        }
    };

    const Body = ({ onRoleChange }) => {
        if (isLoading || !users) {
            return (
                <TableRow>
                    <TableCell colSpan={3} align="center">
                        <CircularProgress/>
                    </TableCell>
                </TableRow>
                
            );
        }

        const rows = rowsPerPage ?
            users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            :
            users;
        return (
            rows.map(u => (
                <TableRow
                    key={u.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell>{ u.name }</TableCell>
                    <TableCell>{ u.email || <em>No email</em> }</TableCell>
                    <TableCell>
                        <RoleSelect user={u} onRoleChange={onRoleChange}/>
                    </TableCell>
                </TableRow>
            ))
        );
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell><strong>Name</strong></TableCell>
                        <TableCell><strong>Email</strong></TableCell>
                        <TableCell><strong>Role</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Body
                            onRoleChange={handleRoleChange}
                        />
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={3}
                                count={users?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                    'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <BasicDialog
                open={!!changingUser}
                onClose={cancelChange}
                title="Confirm"
                content={<span>Are you sure you want to change {changingUser?.name}'s role from {changingUser?.oldRole} to {changingUser?.newRole}</span>}
                actions={
                    <>
                        <Button variant="text" onClick={cancelChange}>Cancel</Button>
                        <Button variant="contained" onClick={confirmChange}>Confirm</Button>
                    </>
                }
            ></BasicDialog>
        </>
    );
};

export default function UserManagement(props) {
    const [ search, setSearch ] = useState(null);
    const [ page, setPage ] = React.useState(0);
    const { isLoading, error, data: users } = useQuery(['users'], () => getUsers());

    const filteredUsers = users?.filter(u => {
        return !search || u.name.toLowerCase().includes(search.toLowerCase()) || (u.email && u.email.toLowerCase().includes(search.toLowerCase()));
    });
    return (
        <>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'end', 
                marginBottom: 5
            }}>
                <OutlinedInput
                    placeholder="Search"
                    size="small"
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    onChange={(e) => {
                        setPage(0);
                        setSearch(e.target.value);
                    }}
                />
            </Box>
            <UserTable isLoading={isLoading} error={error} users={filteredUsers} page={page} setPage={setPage}/>
        </>
    );
}