
import React from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import digital from '../assets_new/digital-sidebar.jpg';
import FadeIn from 'react-fade-in'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '90vh',
    display: 'flex',
    maxWidth: "100%",
    justifyContent: 'flex-start',
    flexDirection: 'row',
  },
  introContainer: {
    maxWidth: "90%",
    margin: "auto",
    marginBottom: "2%",
  },
  sidebarImg: {
    width: '30%',
    height: 'auto',
    objectFit: 'cover'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    width: '30%',
    margin: 'auto'
  },
  title: {
    marginBottom: "4%"
  },
  body: {
    lineHeight: 1.5,
    fontSize: '1rem'
  },
  link: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
}));

function Contact() {
  const classes = useStyles();

  return (
    <FadeIn>
      <div className={classes.root}>
        <img src={digital} alt="" className={classes.sidebarImg} />
        <div className={classes.contentContainer}>
          <Typography className={classes.title} component="div" color="primary" variant="body1">CONTACT US</Typography>
          <Typography className={classes.body} variant="body2">
            Get in touch with JML. To learn more about
            our 3D product development services, request a demo at
            <a className={classes.link} href="mailto:3D@jmlapparel.com"> 3D@jmlapparel.com</a>. Our team is happy to demonstrate
            our value-add 3D design services and help your company
            develop custom trims and notions that fit your seasonal
            concepts. One of our experienced team members will be in
            contact within 48 hours.
          </Typography>
        </div>
      </div>
    </FadeIn>
  );
}

export default Contact;
