import React, { createRef, useState } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import ErrorIcon from '@mui/icons-material/Error';
import ArrowLeft from '@mui/icons-material/ArrowLeft';
import BasicDialog from './BasicDialog';
import { contentStyles } from './dialogStyles';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Typography from '@mui/material/Typography';
import { uploadScans } from '../Api/fabricApi';

export default function ScanUploadDialog(props) {
    const contentClasses = contentStyles();
    const { open, onClose } = props;
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ files, setFiles ] = useState(undefined);
    const [ error, setError ] = useState(null);
    const [ loading, setLoading ] = useState(false);

    const fileInputRef = createRef();
    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const importFiles = (files) => {
        setLoading(true);
        setCurrentStep(1);
        uploadScans(files)
          .then(() => {
            setLoading(false);
            handleClose();
          })
          .catch(e => {
            const errorMessage = e.response.data?.error;
            setError(errorMessage || "Error uploading file");
          })
          .finally(() => {
            setLoading(false);
          });
    };

    const reset = () => {
        setCurrentStep(0);
        setFiles(undefined);
        setError(null);
        setLoading(false);
    };
    const handleClose = () => {
        reset();
        onClose();
    };
    const backToFileUpload = () => {
        reset();
        setCurrentStep(0);
    }

    const steps = [
        {
          content:
            <div className={contentClasses.container}>
              {
                files ? 
                  <React.Fragment>
                      <CheckCircleOutlineIcon className={`${contentClasses.uploadIcon} ${contentClasses.complete}`}></CheckCircleOutlineIcon>
                      <strong>{files.length} files selected</strong> selected
                  </React.Fragment>
                  : 
                  <React.Fragment>
                      <UploadFileIcon className={contentClasses.uploadIcon} onClick={triggerFileInput}></UploadFileIcon>
                      <Button variant="outlined" onClick={triggerFileInput}>Choose scan files to upload</Button>
                      <Typography color="error">{error}</Typography>
                  </React.Fragment>
                    
              }
              <input className={contentClasses.hiddenInput} type="file" ref={fileInputRef} webkitdirectory="" directory="" multiple onChange={(e) => importFiles(e.target.files)}/>
            </div>,
          actions:
            <React.Fragment>
              <Button autoFocus variant="contained" disabled={!files}>
                Import
              </Button>
            </React.Fragment>
        },
        {
          content:
              <div className={contentClasses.container}>
                {
                  loading &&
                  <React.Fragment>
                    <CircularProgress color="primary" style={{width: 140, height: 140, margin: 25}}></CircularProgress>
                    Importing...
                  </React.Fragment>
                }
                {
                  error &&
                  <React.Fragment>
                    <ErrorIcon className={contentClasses.errorIcon}></ErrorIcon>
                    <Typography color="error">{error}</Typography>
                  </React.Fragment>
                }
              </div>,
          actions: 
              <React.Fragment>
                <Button autoFocus onClick={backToFileUpload} variant="outlined">
                  <ArrowLeft></ArrowLeft> Back
                </Button>
              </React.Fragment>
        }
    ];

    return (
        <BasicDialog
          open={open}
          onClose={handleClose}
          title="Upload Scans"
          content={steps[currentStep].content}
          actions={steps[currentStep].actions}
        ></BasicDialog>
    );
}