import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Hidden from '@mui/material/Hidden';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import { NavLink as RouterLink } from 'react-router-dom';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import logo from '../assets_new/logo-svg.svg';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import { handleLogout } from '../Auth/AuthHandlers';
import { isAdmin } from '../Auth/Authorization';
import { useSession } from '../Auth/SessionHooks';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: "45px",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 2000,
    color: "black",
    backgroundColor: "white",
    // minHeight: "45px",
  },
  listItem: {
    minWidth: 0,
    lineHeight: 1,
    borderRadius: 0,
    letterSpacing: 0,
    textTransform: "none",
    fontWeight: "bold",
    fontFamily: "'Work Sans', sans-serif",
  },
  listItemText: {
    padding: "0.3% 0.5% 0.3% 0.5%",
    fontFamily: "'Work Sans', sans-serif",
    [theme.breakpoints.up('md')]: {
      marginRight: "0.7%",
    },
    color: 'black',
    "&:hover": {
      color: theme.palette.primary.main,
    },
  },
  logoButtonText: {
    padding: "0px",
    // maxWidth: "130%"
  },
  menuButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  toolBarRegular: {
    //minHeight: "45px",
  },
  logo: {
    flexGrow: 1,
    justifyContent: "left",
  },
  logoImg: {
    maxWidth: "130%",
  },

}));



function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

function ListItemLink(props) {
  const { primary, to, classes } = props;

  return (
    <li>
      <ListItem
        button
        component={RouterLink}
        classes={classes}
        exact
        to={to}
        activeclassname="highlighted"
        activestyle={{ color: '#009966' }}>
        <ListItemText primary={primary} />
      </ListItem>
      <Divider style={{ background: "white", height: "1.5px", width: "95%", margin: "auto" }} />
    </li>
  );
}

ListItemLink.propTypes = {
  primary: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};


function NavItem(props) {
  const { to } = props;
  const classes = useStyles();

  return (
    <Button
      color="inherit"
      activeClassName="highlighted"
      activeStyle={{ color: '#009966' }}
      classes={{ root: classes.listItem, text: classes.listItemText }}
      component={RouterLink}
      exact
      to={to}>
      {props.children} </Button>
  );
}

function ResponsiveDrawer() {
  const classes = useStyles();
  const container = React.useRef(null);
  const { instance } = useMsal();
  const session = useSession();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <ElevationScroll >

        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar classes={{ regular: classes.toolBarRegular }}>
            <div className={classes.logo} >
              <Button color="inherit" component={RouterLink} classes={{ text: classes.logoButtonText }} to="/">
                <img
                  src={logo}
                  alt="JML logo"
                  className={classes.logoImg}></img></Button>
            </div>

            <Hidden smDown>
              <UnauthenticatedTemplate>
                <NavItem to="/">Log In</NavItem>
                <NavItem to="/contact-us">Contact Us</NavItem>
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                {(isAdmin(session)) && <NavItem to="/admin/inventory-management">Admin</NavItem>}
                <NavItem to="/jml-library">JML Library</NavItem>
                <NavItem to="/brand-library">Brand Library</NavItem>
                <NavItem to="/training-center">Training Center</NavItem>
                <NavItem to="/contact-us">Contact Us</NavItem>
                <Button
                  classes={{ root: classes.listItem, text: classes.listItemText }}
                  onClick={() => handleLogout(instance)}

                >Log Out</Button>
              </AuthenticatedTemplate>
            </Hidden>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      {/* The implementation can be swapped with js to avoid SEO duplication of links. do we even need css */}
      <Hidden smUp implementation="css">
        <div ref={container} />
      </Hidden>

      <Toolbar />
    </div>
  );
}


export default ResponsiveDrawer;
