import React from 'react';
import { useHistory } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import DownloadMaterial from './DownloadMaterial';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { isBrowseAndDownload } from '../Auth/Authorization';
import { useSession } from '../Auth/SessionHooks';

const EMPTY_PLACEHOLDER = "N/A";
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        width: "90%",
        [theme.breakpoints.up('lg')]: {
            width: '70%',
            // height: "auto",
        },
        [theme.breakpoints.up('xl')]: {
            width: '50%',
            // height: "auto",
        },
        margin: 'auto',
    },
    introContainer: {
        display: "flex",
        flexDirection: "column",
        // justifyContent: "center",
        maxWidth: "55%",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "4%",
    },
    mainContent: {
        display: "flex",
        flexDirection: "row",
        marginTop: "5%",
        justifyContent: "space-between"
    },
    aside: {
        marginTop: "4%",
        marginLeft: "4%",
        fontSize: theme.typography.body2.fontSize,
        borderRadius: 0
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "flex-start"
    },
    itemContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    item: {
        background: "rgba(194, 194, 194, 0.2)",
        padding: "3% 4.5% 3% 4.5%",
        marginBottom: "10px",
        [theme.breakpoints.down('sm')]: {
            padding: "4% 4.5% 4% 4.5%",
        },
    },
    itemTitle: {
        textTransform: "uppercase",
        marginBottom: "10px",
        [theme.breakpoints.down('md')]: {
            marginTop: "17px",
        },
    },
    description: {
        maxWidth: "90%",
        [theme.breakpoints.down('md')]: {
            maxWidth: "100%"
        },
    },
    imgBackground: {
        display: 'flex',
        alignItems: 'center',
        height: "300px",
        width: '300px',
        //padding: "10px 16px",
        backgroundColor: '#d8e0e3',
        boxShadow: 'inset 4em 3em 9em #9aa5a9',
        marginRight: '4%',
        overflow: 'hidden',
        zIndex: 3199,
        justifyContent: 'center'
    },
    img: {
        '-webkit-filter': 'drop-shadow(0 5px 1px rgba(0,0,0,0.5))',
        filter: 'drop-shadow(0 5px 1px rgba(0,0,0,0.5))'
    },
    descriptionField: {
        display: 'flex',
        displayDirection: 'row'
    }
}));

const getFiberContent = (fd) => {
    const results = fd.fiberContent.map((material) => {
        const content = `${material.percentComposition}% ${material.fiberContent}`;
        return (<div style={{
            whiteSpace: "nowrap"
        }}> {content}</div >);
    });
    return ['Fiber Content', results];
};

const getSampleWidth = (fd) => {
    return fd.sampleWidth == null ? EMPTY_PLACEHOLDER : ["Sample Width", `${fd.sampleWidth} mm`];
};

const getSustainability = (fd) => {
    const results = fd.sustainability?.map((sus) => {
        return <div>{sus}</div>;
    });
    return ['Sustainability', results];
};

export const FabricDetails = (props) => {
    const classes = useStyles();
    let history = useHistory();
    const session = useSession();

    let fd = props.fabricDetails;
    const canBrowseAndDownload = isBrowseAndDownload(session) ||
        (session?.groups.length && !session?.groups.find(g => g.name.toLowerCase() === fd.brand.toLowerCase()).role === 'BROWSE_AND_DOWNLOAD');
    let fieldNames = [["Brand Item Code", fd.brandItemCode], ["Product Category", fd.productCategory], ["Application", fd.application], ["Supplier Article Number", fd.supplierArticleNumber], ["Textile Type", fd.textileType], getFiberContent(fd),
    ["Treatment", fd.treatment], ["Country of Origin", fd.countryOfOrigin], getSampleWidth(fd), getSustainability(fd)
    ];

    fieldNames = fieldNames.filter((field) => {
        return field && field.length > 1 && field[1] && field[1] !== EMPTY_PLACEHOLDER;
    });

    return (
        <div className={classes.root}>
            <Button
                className={classes.aside}
                onClick={history.goBack}
                color="primary"
                disableRipple
                startIcon={<ArrowBackIosIcon fontSize="small" />}
            >Back</Button>

            <div className={clsx(classes.introContainer)}>
                <div className={classes.header}>
                    <div>
                        <Typography variant="h3">{fd.brandItemCode}</Typography>
                        {/* {fd.materialName !== EMPTY_PLACEHOLDER && <Typography variant="body1">{fd.materialName}</Typography>} */}
                    </div>
                    {
                        canBrowseAndDownload && <DownloadMaterial brandName={fd.brand} fabricId={fd.fileName} fileTypes={new Set(fd.fileTypes)} />
                    }
                </div>
                {/* mainContent */}
                <div className={classes.mainContent}>
                    <div className={classes.imgBackground}>
                        <img src={props.fabricDetails.url} alt={props.fabricDetails.materialName} className={classes.img} />
                    </div>
                    <Grid container xs={6} spacing={3} >

                        {fieldNames.map(data => (
                            <>
                                <Grid item xs={5} >
                                    <Typography variant="body1">
                                        {data[0]}
                                    </Typography>
                                </Grid>
                                <Grid item xs={7} >
                                    <Typography variant="body1">
                                        {data[1]}
                                    </Typography>
                                </Grid>
                            </>
                        ))}

                    </Grid>
                </div>
            </div>
        </div>
    );
};
