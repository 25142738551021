import React from 'react';
import { makeStyles } from '@mui/styles';
import Award01 from '../assets_new/frontpage/bluesign-logo.png';
import Award02 from '../assets_new/frontpage/oeko-tex.png';
import Award03 from '../assets_new/frontpage/higg-index-logo.png';
import Award04 from '../assets_new/frontpage/grs-logo.png';
import Award05 from '../assets_new/frontpage/zdhc.jpg';
import Cert01 from '../assets_new/frontpage/tex-trends-selection-fw2526.jpg';
import Cert02 from '../assets_new/frontpage/tex-trends-top10-fw2526.jpg';
import Cert03 from '../assets_new/frontpage/tex-trends-selection-fw2425.png';
import Cert04 from '../assets_new/frontpage/tex-trends-top10-fw-2425.png';
import Cert05 from '../assets_new/frontpage/tex-trends-top-10-fw2324.png';
import { Typography } from '@mui/material';



const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "90%",
    margin: "auto",
    paddingTop: "2%",
    paddingBottom: "2%",
  },
  title: {
    textAlign: "center",
  },
  awards: {
    display: "flex",
    flexFlow: "row wrap",
    justifyContent: "center",
    padding: 0,
    margin: 0,
    listStyle: "none",
    "& img": {
      maxHeight: "75px",
      padding: "5%"
    },

  },
  awardsContainer: {
    width: "80%",
    margin: "5px auto",
    listStyle: "none",
    display: "flex",
    justifyContent: "center",
    "& img": {
      maxHeight: "75px",
      padding: "5%"
    },
    "& li": {
      minWidth: "150px",
      maxWidth: "250px",
      textAlign: "center",
      display: "flex",
      flexWrap: "wrap",
      alignContent: "center",
      alignItems: "center",
    },
  }
}));


function Item(props) {
  const classes = useStyles();

  return (
    <li >
      <img src={props.image} alt="award" className={classes.item} style={props.style} />
    </li>
  );

}


function Awards() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.awards}>
        <Typography variant="header" color="primary">AWARDS & ACCREDITATIONS
        </Typography>
      </div>
      <ul className={classes.awardsContainer}>
        <Item image={Award01} />
        <Item image={Award02} style={{ minHeight: "120px" }} />
        <Item image={Award03} />
        <Item image={Award04} />
        <Item image={Award05} />
      </ul>
      <ul className={classes.awardsContainer}>
        <Item image={Cert01} style={{ minHeight: "110px" }} />
        <Item image={Cert02} style={{ minHeight: "110px" }} />
        <Item image={Cert03} style={{ minHeight: "110px" }} />
        <Item image={Cert04} style={{ minHeight: "110px" }} />
        <Item image={Cert05} style={{ minHeight: "110px" }} />
      </ul>
    </div >
  );
}


export default Awards;
