/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
 export const b2cPolicies = {
    names: {
        signIn: "B2C_1_B2C_signin_flow",
        passwordReset: "B2C_1_password_reset_flow"
        // editProfile: "B2C_1_edit_profile_v2"
    },
    authorities: {
        signIn: {
            authority: "https://3dlibrary.b2clogin.com/3dlibrary.onmicrosoft.com/B2C_1_B2C_signin_flow"
        },
        passwordReset: {
            authority: "https://3dlibrary.b2clogin.com/3dlibrary.onmicrosoft.com/B2C_1_password_reset_flow"
        }
        // editProfile: {
        //     authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_edit_profile_v2"
        // }
    },
    authorityDomain: "3dlibrary.b2clogin.com"
}

