
import React from "react";
import { useLocation } from 'react-router-dom';

// Msal imports
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

// Sample app imports
import { Loading } from "./Loading";
import Error from "./Error";

import { isAdmin } from '../Auth/Authorization';
import { useSession } from "./SessionHooks";

export function AdminProtected(props) {
    const location = useLocation()
    const session = useSession();

    const authRequest = {
        ...loginRequest
    };

    const childrenWithProps = React.Children.map(props.children, (child, index) => {
        return React.cloneElement(child, location.state);
      });

    const errorTemplate = <Error></Error>;
    const normalTemplate = 
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Popup}
            authenticationRequest={authRequest}
            errorComponent={Error}
            loadingComponent={Loading}
        >
            {childrenWithProps}
        </MsalAuthenticationTemplate>;

    return (
        <div>
            {
                isAdmin(session) ?
                    normalTemplate
                    :
                    errorTemplate
            }
        </div>
    )
};