
import React from 'react';
import { makeStyles } from '@mui/styles';
import {  Typography } from '@mui/material';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const useStyles = makeStyles((theme) => ({
    tableHeader: {
        background: "#efefef",
        position: 'sticky',
        top: '63px',
        zIndex: '1000',
        height: '70px',
    },
    
}));

export const TableHeader = ({attributeColumns, extraColumns}) => {
    const classes = useStyles();
    const hrv = "body1"
    return (
        <TableHead className={classes.tableHeader}>
            <TableRow>
                <TableCell width="5%" ></TableCell>
                <TableCell size="small"><Typography variant={hrv}>Brand Item Code</Typography></TableCell>
                {/* <TableCell align="left"><Typography variant={hrv}>Description</Typography></TableCell>
                <TableCell align="left"><Typography variant={hrv}>Product Category</Typography></TableCell>
                <TableCell align="left"><Typography variant={hrv}>Application</Typography></TableCell>
                <TableCell align="left"><Typography variant={hrv}>Textile Type</Typography></TableCell>
                <TableCell align="left"><Typography variant={hrv}>Supplier Article Number</Typography></TableCell>
                <TableCell align="left"><Typography variant={hrv}>Treatment</Typography></TableCell>
                <TableCell  align="left"><Typography variant={hrv}>Sustainability</Typography></TableCell>
                <TableCell align="left"><Typography variant={hrv}>Width (mm)</Typography></TableCell> */}
                {
                    attributeColumns?.map(c =>
                        <TableCell align="left" key={c.header}>
                            <Typography variant={hrv}>{c.header}</Typography>
                        </TableCell>
                    )
                }
                {
                    extraColumns?.map(c =>
                        <TableCell align="left" key={c.header}>
                            <Typography variant={hrv}>{c.header}</Typography>
                        </TableCell>
                    )
                }
            </TableRow>
        </TableHead>
    );
};
