import React from 'react';
import BasicDialog from './BasicDialog';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const hasScanFiles = (material) => {
    if (!material.fileTypes) {
        return false;
    }

    let hasU3m = false;
    let hasXtex = false;
    for (let i = 0; i < material.fileTypes.length; i++) {
        if (material.fileTypes[i] === 'u3m') {
            hasU3m = true;
        }
        else if (material.fileTypes[i] === 'xtex') {
            hasXtex = true;
        }
    }
    return hasU3m && hasXtex;
}

export default function PublishAllConfirmDialog({ open, onClose, materials, publishMaterialsFunction }) {
    if (!materials) {
        return null;
    }

    const legalMaterials = materials.filter(hasScanFiles);
    const illegalMaterials = materials.filter((m) => !hasScanFiles(m))
    return (
        <BasicDialog
            open={open}
            onClose={onClose}
            title="Confirm publish all"
            content={
                <>
                    <div>Only materials fitting the configured filters will be published.</div>
                    <div>The following materials are missing scan files and will not be published as part of this operation:</div>
                    <TableContainer component={Paper}
                        sx={{
                            maxHeight: 400
                        }}
                    >
                      <Table sx={{ minWidth: 400 }}>
                        <TableHead>
                          <TableRow>
                            <TableCell><strong>Brand</strong></TableCell>
                            <TableCell><strong>Brand Item Code</strong></TableCell>
                            <TableCell><strong>Supplier Article Number</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {illegalMaterials.map((row) => (
                            <TableRow
                              key={'publish-warning-' + row.supplierArticleNumber}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {row.brand}
                              </TableCell>
                              <TableCell>{row.brandItemCode}</TableCell>
                              <TableCell>{row.supplierArticleNumber}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                </>
            }
            actions={
                <>
                    <Button autoFocus onClick={onClose} variant="outlined">
                        Cancel
                    </Button>
                    <Button 
                        autoFocus 
                        onClick={() => {
                            publishMaterialsFunction(legalMaterials);
                            onClose();
                        }} 
                        variant="contained"
                    >
                        Publish All
                    </Button>
                </>
            }
        ></BasicDialog>
    );
}