import axios from 'axios'
import { apiConfig } from './apiConfig'

const api = axios.create({
    baseURL: apiConfig.api
})

export const requestAccount = (data) => {
    console.log('about to send account request')
    return api.post(`/account-request`, data).then(res => res.data)
}
