import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from '../Auth/authConfig';
import { apiConfig } from './apiConfig'
const msalInstance = new PublicClientApplication(msalConfig);

export const acquireToken = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        console.log("no one is here")
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */   
    } else {
        const request = {
            scopes: [...apiConfig.b2cScopes],
            account: activeAccount || accounts[0],
            forceRefresh: false
        };
    
        return await msalInstance.acquireTokenSilent(request);
    }
};