import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx'
import { Typography } from '@mui/material';
import MaterialTable from '../Common/MaterialsTable/MaterialsTable'
import { getSearchFieldsWithOptions, filterMaterials, Search } from '../Common/search';
import CircularProgress from '@mui/material/CircularProgress';
import { EmptyStateWithTable as EmptyState } from '../Common/EmptyState';
import { useHistoryState } from '../Common/useHistoryState';
import { useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useHistory } from "react-router-dom";

import * as api from '../Api/fabricApi.js'
import FadeIn from 'react-fade-in'
import { useQuery } from 'react-query'


const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        [theme.breakpoints.up('xl')]: {
            width: '75vw',
            // height: "auto",
        },
    },
    mainContent: {
        maxWidth: "90%",
        margin: "auto",
        marginBottom: "2%",
        marginTop: "3%",
    },
    circularProgress: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(3),
    },
    emptyState: {
        display: 'flex',
        justifyContent: 'center',
        position: 'absolute'
    },
}));

const searchFields = [
    {
        label: "Product Category",
        key: "productCategory",
        type: "string",
    },

    {
        label: "Application",
        key: "application",
        type: "string",

    },
    {
        label: "Textile Type",
        key: "textileType",
        type: "string",
    },
    {
        label: "Treatment",
        key: "treatment",
        type: "string",
    },
]

export const JMLLibrary = () => {
    return (<BrandLibrary brandName="jml" title="JML Library" searchFields={searchFields} />)
}

export const JMLCollection = () => {
    return (<BrandLibrary brandName="jml" searchFields={searchFields} />)
}

// TODO make user re-enter credentials if acquiretokensilently fails
export const MyBrandLibrary = () => {
    return (<BrandLibrary brandName="myBrand" title="Brand Library" searchFields={searchFields} />)
}

const attributeColumns = [
    {
        header: "Description",
        attribute: "materialName"
    },
    {
        header: "Product Category",
        attribute: "productCategory"
    },
    {
        header: "Application",
        attribute: "application"
    },
    {
        header: "Textile Type",
        attribute: "textileType"
    },
    {
        header: "Supplier Article Number",
        attribute: "supplierArticleNumber"
    },
    {
        header: "Treatment",
        attribute: "treatment"
    },
    {
        header: "Sustainability",
        attribute: "sustainability"
    },
    {
        header: "Width (mm)",
        attribute: "sampleWidth"
    }
];

const BrandLibrary = ({ brandName, title }) => {
    const location = useLocation();
    let history = useHistory();

    if (!title) {
        title = location?.state?.title
    }
    const [query, setQuery] = useHistoryState('fabricQuery', null);
    const [filters, setFilters] = useHistoryState('materialFilters', null)
    const classes = useStyles();

    useEffect(() => {
        if (location.state) { // why are we doing this again? location.state has our collections in it.
            setQuery({ ...location.state.query })
        }
        // eslint-disable-next-line
    }, [])

    const { isLoading, error, data } = useQuery([brandName, query], ({ queryKey }) => api.getFabrics(queryKey[0], queryKey[1]));

    const filterHandler = async (filters) => {
        // setpage(0) // TODO what do we do about this...
        setFilters(filters)
    }

    let filteredFabrics = data?.fabrics;
    if (data && filters && Object.keys(filters).length > 0) {
        filteredFabrics = filterMaterials(data?.fabrics, filters)
    }

    let content;
    if (error || (filteredFabrics && filteredFabrics.length === 0)) {
        content = <EmptyState/>
    } else if (isLoading) {
        content = <div className={classes.circularProgress}><CircularProgress /></div>;
    } else {
        content = <FadeIn>
            <MaterialTable materials={filteredFabrics} attributeColumns={attributeColumns} />
        </FadeIn>;
    }

    return (
        <div className={clsx(classes.root, classes.mainContent)}>
            <Button
                className={classes.aside}
                onClick={() => history.replace('/home')}
                color="primary"
                disableRipple
                // startIcon={<ArrowBackIosIcon fontSize="small" />}
            >Home</Button>
            <div className={classes.header}>
                <Typography variant="header">{title}</Typography>
                <Search searchHandler={filterHandler} searchFields={getSearchFieldsWithOptions(data?.fabrics || [], searchFields)}/>
            </div>
            <div>
                {content}
            </div>
        </div>
    );
};
