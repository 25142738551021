import React, { useState } from 'react';
import { getGroups, updateGroup } from "../Api/groupApi";
import { useQuery, useQueryClient } from 'react-query';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import PropTypes from 'prop-types';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import BasicDialog from './BasicDialog';

function TablePaginationActions(props) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;
  
    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };
  
    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };
  
    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };
  
    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };
  
    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
}
  
TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
};

const GroupTable = ({isLoading, error, groups, page, setPage}) => {
    const [ rowsPerPage, setRowsPerPage] = React.useState(10);
    const [ changingGroup, setChangingGroup ] = useState(null);
    const queryClient = useQueryClient();

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleRoleChange = async (group, newRole) => {
        setChangingGroup({
            ...group,
            oldRole: group.role,
            newRole: newRole
        });
    };
    const cancelChange = async () => {
        setChangingGroup(null);
    };
    const confirmChange = async () => {
        await updateGroup({
            ...changingGroup,
            role: changingGroup.newRole
        });
        queryClient.invalidateQueries(['groups']);
        setChangingGroup(null);
    };

    const RoleSelect = ({ group, onRoleChange }) => {
        const roleOptions = [ 'BROWSE_AND_DOWNLOAD', 'BROWSE_ONLY' ];

        if (roleOptions.includes(group.role)) {
            return (
                <Select
                    size="small"
                    value={group.role || "BROWSE_AND_DOWNLOAD"}
                    onChange={(e) => onRoleChange(group, e.target.value)}
                >
                    {
                        roleOptions.map(o => (
                            <MenuItem key={o} value={o}>{o}</MenuItem>
                        ))
                    }
                </Select>
            );
        } else {
            return (
                <span>{group.role}</span>
            );
        }
    };

    const Body = ({ onRoleChange }) => {
        if (isLoading || !groups) {
            return (
                <TableRow>
                    <TableCell colSpan={2} align="center">
                        <CircularProgress/>
                    </TableCell>
                </TableRow>
                
            );
        }

        const rows = rowsPerPage ?
            groups.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            :
            groups;
        return (
            rows
                .sort((a, b) => {
                    if (a.name > b.name) {
                        return 1;
                    }
                    else if (b.name > a.name) {
                        return -1;
                    }
                    else {
                        return 0;
                    }
                })
                .map(g => (
                <TableRow
                    key={g.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell>{ g.name }</TableCell>
                    <TableCell>
                        <RoleSelect group={g} onRoleChange={onRoleChange}/>
                    </TableCell>
                </TableRow>
            ))
        );
    };

    return (
        <>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                        <TableCell><strong>Name</strong></TableCell>
                        <TableCell><strong>Default User Role</strong></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <Body
                            onRoleChange={handleRoleChange}
                        />
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[]}
                                colSpan={2}
                                count={groups?.length || 0}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                    inputProps: {
                                    'aria-label': 'rows per page',
                                    },
                                    native: true,
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <BasicDialog
                open={!!changingGroup}
                onClose={cancelChange}
                title="Confirm"
                content={<span>Are you sure you want to change {changingGroup?.name}'s role from {changingGroup?.oldRole} to {changingGroup?.newRole}</span>}
                actions={
                    <>
                        <Button variant="text" onClick={cancelChange}>Cancel</Button>
                        <Button variant="contained" onClick={confirmChange}>Confirm</Button>
                    </>
                }
            ></BasicDialog>
        </>
    );
};

export default function GroupManagement(props) {
    const [ search, setSearch ] = useState(null);
    const [ page, setPage ] = React.useState(0);
    const { isLoading, error, data: groups } = useQuery(['groups'], () => getGroups());

    const filteredGroups = groups
        ?.filter(g => g.name !== 'jml')
        ?.filter(g => {
            return !search || g.name.toLowerCase().includes(search.toLowerCase());
        });
    return (
        <>
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'end', 
                marginBottom: 5
            }}>
                <OutlinedInput
                    placeholder="Search"
                    size="small"
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }
                    onChange={(e) => {
                        setPage(0);
                        setSearch(e.target.value);
                    }}
                />
            </Box>
            <GroupTable isLoading={isLoading} error={error} groups={filteredGroups} page={page} setPage={setPage}/>
        </>
    );
}