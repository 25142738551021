import React from "react";

export function useHistoryState(key, defaultValue) {
    const [state, rawSetState] = React.useState(() => {
        const value = window.history.state && window.history.state[key];
        return value || defaultValue
    })

    function setState(value) {
        window.history.replaceState(
            { ...window.history.state, [key]: value },
            document.title
          );
          rawSetState(value);
    }

    return [state, setState];
}