import * as React from 'react';
import InventoryManagement from './InventoryManagement';
import UserManagement from './UserManagement';
import GroupManagement from './GroupManagement';
import { makeStyles } from '@mui/styles';
import baseStyles from '../Common/styles';
import SideNav from './SideNav';
import { Route } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    ...baseStyles(theme)
}));

export default function Admin() {
    const classes = useStyles();

    return (
        <div className={classes.sideNavRoot}>
            <div className={classes.sideNav}>
                <SideNav></SideNav>
            </div>
            <div className={classes.mainContent}>
                <Route path='/admin/inventory-management'>
                    <InventoryManagement/>
                </Route>
                <Route path='/admin/user-management'>
                    <UserManagement/>
                </Route>
                <Route path='/admin/group-management'>
                    <GroupManagement/>
                </Route>
            </div>
        </div>
    );
}