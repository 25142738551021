import { PublicClientApplication} from "@azure/msal-browser";
import { msalConfig } from "./authConfig"
const instance = new PublicClientApplication(msalConfig);

// let accountId;
// let username;

function selectAccount () {
    const currentAccounts = instance.getAllAccounts();

    if (currentAccounts.length === 0) {
        return;
    } else if (currentAccounts.length > 1) {
        // Add your account choosing logic here
        console.log("Multiple accounts detected.");
    } else if (currentAccounts.length === 1) {
        // accountId = currentAccounts[0].homeAccountId; // todo, not sure what to do here or below
        // username = currentAccounts[0].username;
        // welcomeUser(username);
    }
}

selectAccount();

function handleResponse(response) {
    if (response !== null) {
        // accountId = response.account.homeAccountId;
        // username = response.account.username;
       // welcomeUser(username);
    } else {
        selectAccount();
    }
}

function handlePolicyChange(response) {
    // if (response.idTokenClaims['acr'] === b2cPolicies.names.editProfile) {
    //     window.alert("Profile has been updated successfully. \nPlease sign-in again.");
    //     myMSALObj.logout();
    // } else 
    if (response.idTokenClaims['acr'] === msalConfig.b2cPolicies.names.passwordReset) {
        window.alert("Password has been reset successfully. \nPlease sign-in with your new password.");
        handleLogout(instance)
    }
}


export const handleLogin = (msalInstance) => {
    let loginType = "popup"
    if (loginType === "popup") {
        msalInstance.loginPopup().then(handleResponse)
        .catch(error => {
            console.log(error);
            if (error.errorMessage) {
                if (error.errorMessage.indexOf("AADB2C90118") > -1) {
                    instance.loginPopup(msalConfig.b2cPolicies.authorities.passwordReset)
                        .then(response => handlePolicyChange(response));
                }
            }
        });
    } else if (loginType === "redirect") {
        msalInstance.loginRedirect();
    }
}

export const handleLogout = (msalInstance) => {
    let logoutType = "redirect"

    const logoutRequest = {
        postLogoutRedirectUri: "/"
    }
    if (logoutType === "popup") {
        msalInstance.logoutPopup(logoutRequest);
    } else if (logoutType === "redirect") {
        msalInstance.logoutRedirect(logoutRequest);
    }
}

// export const SignOutButton = () => {
//     const { instance } = useMsal();

//     const [anchorEl, setAnchorEl] = useState(null);
//     const open = Boolean(anchorEl);

//     const handleLogout = (logoutType) => {
//         setAnchorEl(null);

//         if (logoutType === "popup") {
//             instance.logoutPopup();
//         } else if (logoutType === "redirect") {
//             instance.logoutRedirect();
//         }
//     }

//     return (
//         <div>
//             <Button
//                onClick={() => handleLogout("popup")}
//                 color="inherit"
//             >
//                 Log Out
//             </Button>
//             {/* <Menu
//                 id="menu-appbar"
//                 anchorEl={anchorEl}
//                 anchorOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//                 }}
//                 keepMounted
//                 transformOrigin={{
//                 vertical: 'top',
//                 horizontal: 'right',
//                 }}
//                 open={open}
//                 onClose={() => setAnchorEl(null)}
//             >
//                 <MenuItem onClick={() => handleLogout("popup")} key="logoutPopup">Logout with Popup</MenuItem>
//                 <MenuItem onClick={() => handleLogout("redirect")} key="logoutRedirect">Logout with Redirect</MenuItem>
//             </Menu> */}
//         </div>
//     )
// };