import React from 'react';
import Awards from '../Footer/Awards';
import Login from './Login';
import Home from './Home';
import { makeStyles } from '@mui/styles';
import { useIsAuthenticated } from '@azure/msal-react';
import clsx from 'clsx';
import FadeIn from 'react-fade-in';

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '90vh',
    textAlign: 'center',
  },
  section: {
    marginBottom: "1%",
    backgroundColor: "white",
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    margin: '5%'
  },
}));

function Landing() {
  const classes = useStyles();
  const isAuthenticated = useIsAuthenticated();

  return (
    <FadeIn>
      <div className={clsx(classes.root)}>
        {isAuthenticated ? <Home /> : <Login />}
      </div>
      {isAuthenticated ? <Awards /> : <></>}
    </FadeIn>
  );
}


export default Landing;
