/**
 * Configuration object to be passed to MSAL instance on creation. 
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 * For more details on using MSAL.js with Azure AD B2C, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/working-with-b2c.md 
 */
import { b2cPolicies } from "./policies";
import { apiConfig } from "../Api/apiConfig";


export const msalConfig = {
  auth: {
    clientId: "3dfd61a5-eca4-45d1-8f98-787d9286b3ae", // This is the ONLY mandatory field; everything else is optional.
    authority: b2cPolicies.authorities.signIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.
    redirectUri:
      process.env.NODE_ENV === 'production' ? "https://3d.jmlapparel.com/" : // "https://proud-tree-03ab58910.azurestaticapps.net/" :
        process.env.REACT_APP_REDIRECT_URI, // You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    //  redirectUri: "https://proud-tree-03ab58910.azurestaticapps.net/home"
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  //  system: {
  //    loggerOptions: {
  //      loggerCallback: (level, message, containsPii) => {
  //        if (containsPii) {
  //          return;
  //        }
  //        switch (level) {
  //          case msal.LogLevel.Error:
  //            console.error(message);
  //            return;
  //          case msal.LogLevel.Info:
  //            console.info(message);
  //            return;
  //          case msal.LogLevel.Verbose:
  //            console.debug(message);
  //            return;
  //          case msal.LogLevel.Warning:
  //            console.warn(message);
  //            return;
  //        }
  //      }
  //    }
  //  }
};

//  const msal = new PublicClientApplication(msalConfig);

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
  scopes: [...apiConfig.b2cScopes],
  forceRefresh: false
};

/**
 * Scopes you add here will be used to request a token from Azure AD B2C to be used for accessing a protected resource.
 * To learn more about how to work with scopes and resources, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */

//  const tokenRequest = {
//    scopes: [...apiConfig.b2cScopes],  // e.g. ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
//    forceRefresh: false // Set this to "true" to skip a cached token and go to the server to get a new token
//  };




// // Config object to be passed to Msal on creation
// export const msalConfig = {
//     auth: {
//         clientId: "9067c884-9fa6-414f-9aa4-a565b1cb46be",
//         authority: "https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_susi_reset_v2",
//         knownAuthorities: ["fabrikamb2c.b2clogin.com"],
//         redirectUri: "http://localhost:4200",
//         postLogoutRedirectUri: "http://localhost:4200"
//     }
// };

// // Scopes you add here will be prompted for consent during login
// export const loginRequest = {
//     scopes: ["https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read"]
// };
