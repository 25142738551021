import React from 'react';
import { ImageLoader } from './ImageLoader';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
    thumbnail: {
        // boxShadow: "0 5px 5px rgba(0,0,0,0.4)",
        '-webkit-filter': 'drop-shadow(0 5px 1px rgba(0,0,0,0.5))',
        filter: 'drop-shadow(0 5px 1px rgba(0,0,0,0.5))'
    },
    imgBackground: {
        display: 'flex',
        alignItems: 'center',
        height: "250px", // 100 TODO - need to make this a variable
        width: '250px', //115 TODO - need to make this a variable
        transition: '0.2s',
        overflow: 'hidden',
        backgroundColor: '#d8e0e3',
        boxShadow: 'inset 3em 2em 6em #9aa5a9',
        '&:hover': {
            transform: 'scale(1.05)',
        },
    }
}));

function ProductThumbnail({ image, height, width }) {
    const classes = useStyles();
    return (
        <div className={classes.imgBackground} >
            <ImageLoader src={image} className={classes.thumbnail} alt="" height={height} width={width} />
        </div>
    )
}

export default ProductThumbnail;