
import React from "react";
import { useLocation } from 'react-router-dom';

// Msal imports
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "./authConfig";

// Sample app imports
import { Loading } from "./Loading";
import Error from "./Error";



export function Protected(props) {
    const location = useLocation()

    const authRequest = {
        ...loginRequest
    };

    const childrenWithProps = React.Children.map(props.children, (child, index) => {
        return React.cloneElement(child, location.state);
      });

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Popup}
            authenticationRequest={authRequest}
            errorComponent={Error}
            loadingComponent={Loading}
        >
            {childrenWithProps}
        </MsalAuthenticationTemplate>
    )
};