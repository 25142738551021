import * as React from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InventoryIcon from '@mui/icons-material/Inventory';
import PeopleIcon from '@mui/icons-material/People';
import GroupsIcon from '@mui/icons-material/Groups';
import { Link as RouterLink, useLocation } from "react-router-dom";

function ListItemLink(props) {
    const { icon, primary, to, selected } = props;
  
    const renderLink = React.useMemo(
      () =>
        React.forwardRef(function Link(itemProps, ref) {
          return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
        }),
      [to],
    );
  
    return (
      <li>
        <ListItem button selected={selected} component={renderLink}>
          {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
          <ListItemText primary={primary} />
        </ListItem>
      </li>
    );
}

export default function SideNav() {
    const location = useLocation();
    return (
        <Box sx={{ borderRight: '1px solid #ccc', height: '100%' }}>
            <List>
                <ListItemLink selected={location.pathname === "/admin/inventory-management"} to="/admin/inventory-management" primary="Inventory" icon={<InventoryIcon />} />
                <ListItemLink selected={location.pathname === "/admin/user-management"} to="/admin/user-management" primary="Users" icon={<PeopleIcon />} />
                <ListItemLink selected={location.pathname === "/admin/group-management"} to="/admin/group-management" primary="Groups" icon={<GroupsIcon />} />
            </List>
        </Box>
    );
};