import React, { useState } from 'react';
import clsx from 'clsx'

export const ImageLoader = ({ src, className, alt, height, width }) => {
  const [loaded, setLoaded] = useState(false)
  const [failed, setFailed] = useState(false);

  if (failed) {
    return <div style={{ margin: 'auto' }}>No image</div>;
  }
  else {
    return <img src={src} onLoad={() => setLoaded(true)} onError={() => setFailed(true)} alt={alt} className={clsx(`img-fluid mb-2 ${loaded ? "d-block" : "d-none"}`, className)} />
  }
}